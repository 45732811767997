import axios from "axios";
import ConstantList from "../../appConfig";

const API_PATH = ConstantList.API_ENPOINT + "/public/contact";

export const saveItem = (item) => {
  return axios.post(API_PATH, item);
};
export const updateItem = (item) => {
  return axios.put(API_PATH + "/" + item.id, item);
};
