import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";

const PositionTitleIndex = EgretLoadable({
  loader: () => import("./PositionTitleIndex"),
});
const ViewComponent = PositionTitleIndex;

const Routes = [
  {
    path: ConstantList.ROOT_PATH + "east-star-shipping/category/positiontitle",
    exact: true,
    component: ViewComponent,
  },
];

export default Routes;
