import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";

const EmployeeStatusIndex = EgretLoadable({
  loader: () => import("./EmployeeStatusIndex"),
});
const ViewComponent = EmployeeStatusIndex;

const Routes = [
  {
    path: ConstantList.ROOT_PATH + "east-star-shipping/category/employeestatus",
    exact: true,
    component: ViewComponent,
  },
];

export default Routes;
