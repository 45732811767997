import { makeAutoObservable } from "mobx";
import {
  uploadImage,
  getGeneralInformation,
  createGeneralInformation,
  editGeneralInformation,
} from "../StaffService";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
toast.configure({
  autoClose: 2000,
  draggable: false,
  limit: 3,
});

export default class GeneralInformationStore {
  initialGeneralInformation = {
    id: "",
    firstName: "",
    lastName: "",
    displayName: "",
    gender: "",
    birthDate: null,
    birthPlace: "",
    permanentResidence: "",
    accommodationToday: "",
    idNumber: "",
    idNumberIssueDate: null,
    idNumberIssueBy: "",
    wards: "",
    nationality: null,
    ethnics: null,
    religion: null,
    email: "",
    phoneNumber: "",
    maritalStatus: "",
  };
  selectedGeneralInformation = null;
  loadingInitial = false;
  checkUpdateGeneralInformation = false;
  constructor() {
    makeAutoObservable(this);
  }

  setLoadingInitial = (state) => {
    this.loadingInitial = state;
  };

  handleClose = () => {
    this.shouldOpenEditorDialog = false;
    this.shouldOpenConfirmationDialog = false;
    this.shouldOpenConfirmationDeleteListDialog = false;
    this.shouldOpenImportExcelDialog = false;
    this.updatePageData();
  };

  getGeneralInformation = async (id) => {
    this.loadingInitial = true;
    if (id != null) {
      try {
        let data = await getGeneralInformation(id);
        this.handleSelectGeneralInformation(data.data);

        this.setLoadingInitial(false);
        return this.selectedGeneralInformation;
      } catch (error) {
        console.log(error);
        toast.warning("toast.get_fail");

        this.setLoadingInitial(false);
      }
    } else {
      this.handleSelectGeneralInformation(this.initialGeneralInformation);
      this.setLoadingInitial(false);
    }
  };

  handleSelectGeneralInformation = (staff) => {
    this.selectedGeneralInformation = staff;
    console.log(this.selectedGeneralInformation);
  };

  handleCheckUpdateGeneralInformation = (check) => {
    this.checkUpdateGeneralInformation = check;
  };

  uploadImage = async (file) => {
    try {
      await uploadImage(file);
      toast.success("toast.delete_success");
    } catch (error) {
      console.log(error);
      toast.warning("toast.delete_fail");
    }
  };

  createGeneralInformation = async (staff) => {
    console.log(staff);

    try {
      if (staff && staff.file != null) {
        const formData = new FormData();
        formData.append("uploadfile", staff.file);
        let response = await uploadImage(formData);
        console.log(response);
        staff.imagePath = response.data.name;
      }

      await createGeneralInformation(staff);
      toast.success("toast.add_success");
      this.handleClose();
    } catch (error) {
      console.log(error);
      toast.warning("toast.add_fail");
    }
  };

  editGeneralInformation = async (staff) => {
    try {
      if (staff && staff.file != null) {
        const formData = new FormData();
        formData.append("uploadfile", staff.file);
        let response = await uploadImage(formData);
        console.log(response);
        staff.imagePath = response.data.name;
      }
      await editGeneralInformation(staff);
      toast.success("toast.update_success");
      this.handleClose();
    } catch (error) {
      console.log(error);
      toast.warning("toast.update_fail");
    }
  };
}
