import { makeAutoObservable } from "mobx";
import {
  uploadImage,
  getProfileInformation,
  createProfileInformation,
  editProfileInformation,
} from "../StaffService";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
toast.configure({
  autoClose: 2000,
  draggable: false,
  limit: 3,
});

export default class ProfileInformationStore {
  initialProfileInformation = {
    id: "",
    status: null,
    department: null,
    staffCode: "",
    civilServantType: null,
    civilServantCategory: null,
    grade: null,
    labourAgreementType: null,
    contractDate: null,
    recruitmentDate: null,
    professionalTitles: "",
    profession: null,
    highestPosition: "",
    dateOfReceivingPosition: null,
    positionDecisionNumber: "",
    currentWorkingStatus: "",
    startDate: null,
    jobTitle: "",
    allowanceCoefficient: "",
    dateOfReceivingAllowance: null,
    salaryLeve: "",
    salaryCoefficient: "",
    salaryStartDate: null,
    socialInsuranceNumber: "",
  };
  selectedProfileInformation = null;
  loadingInitial = false;
  constructor() {
    makeAutoObservable(this);
  }

  setLoadingInitial = (state) => {
    this.loadingInitial = state;
  };

  handleClose = () => {
    this.shouldOpenEditorDialog = false;
    this.shouldOpenConfirmationDialog = false;
    this.shouldOpenConfirmationDeleteListDialog = false;
    this.shouldOpenImportExcelDialog = false;
    this.updatePageData();
  };

  getProfileInformation = async (id) => {
    this.loadingInitial = true;
    if (id != null) {
      try {
        let data = await getProfileInformation(id);
        this.handleSelectProfileInformation(data.data);

        this.setLoadingInitial(false);
        return this.selectedProfileInformation;
      } catch (error) {
        console.log(error);
        toast.warning("toast.get_fail");

        this.setLoadingInitial(false);
      }
    } else {
      this.handleSelectProfileInformation(this.initialProfileInformation);
      this.setLoadingInitial(false);
    }
  };

  handleSelectProfileInformation = (staff) => {
    this.selectedProfileInformation = staff;
  };

  uploadImage = async (file) => {
    try {
      await uploadImage(file);
      toast.success("toast.delete_success");
    } catch (error) {
      console.log(error);
      toast.warning("toast.delete_fail");
    }
  };

  createProfileInformation = async (staff) => {
    try {
      await createProfileInformation(staff);
      toast.success("toast.add_success");
      this.handleClose();
    } catch (error) {
      console.log(error);
      toast.warning("toast.add_fail");
    }
  };

  editProfileInformation = async (staff) => {
    try {
      await editProfileInformation(staff);
      toast.success("toast.update_success");
      this.handleClose();
    } catch (error) {
      console.log(error);
      toast.warning("toast.update_fail");
    }
  };
}
