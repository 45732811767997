import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { withTranslation } from "react-i18next";

const TrainingCenter = EgretLoadable({
    loader: () => import("./Index")
});
const ViewComponentFleet = withTranslation()(TrainingCenter);

const trainingRoute = [{
    path: ConstantList.ROOT_PATH + "east-star-shipping/training-center",
    component: ViewComponentFleet
},];

export default trainingRoute;
