import React from "react";
import { Redirect } from "react-router-dom";
import sessionRoutes from "./views/sessions/SessionRoutes";
import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import userRoutes from "./views/User/UserRoutes";
import roleRoutes from "./views/Role/RoleRoutes";
import ConstantList from "./appConfig";
import colorsRoutes from "./views/Colors/ColorRoutes";
import academicRoutes from "./views/Academic/AcademicRoutes";
import ethnicsRoutes from "./views/Ethnics/EthnicsRoutes";
import religionRoutes from "./views/Religion/ReligionRoutes";
import countryRoutes from "./views/Country/CountryRoutes";
import staffRoutes from "./views/HumanResourcesInformation/StaffRoutes";
import positionTitleRoutes from "./views/PositionTitle/PositionTitleRoutes";
import certificateRoutes from "./views/Certificate/CertificateRoutes";
import DepartmentRoutes from "./views/Department/DepartmentRoutes";
import civilServantTypeRoutes from "./views/CivilServantType/CivilServantTypeRoutes";
import rewardRoutes from "./views/Reward/RewardRoutes";
import contractTypeRoutes from "./views/ContractType/ContractTypeRoutes";
import employeeStatusRoutes from "./views/EmployeeStatus/EmployeeStatusRoutes";
import locationRoutes from "./views/Location/LocationRoutes";
import civilServantCategoryRoutes from "./views/CivilServantCategory/CivilServantCategoryRoutes";
import professionRoutes from "./views/Profession/ProfessionRoutes";
import disciplineRoutes from "./views/Discipline/DisciplineRoutes";
import gradeRoutes from "./views/Grade/GradeRoutes";
import educationDegreeRoutes from "./views/EducationDegree/EducationDegreeRoutes";
import educationTypeRoutes from "./views/EducationType/EducationTypeRoutes";
import specialityRoutes from "./views/Speciality/SpecialityRoutes";
import professionalDegreeRoutes from "./views/ProfessionalDegree/ProfessionalDegreeRoutes";
import informaticDegreeRoutes from "./views/InformaticDegree/InformaticDegreeRoutes";
import politicaltheoryLevelRoutes from "./views/PoliticaltheoryLevel/PoliticaltheoryLevelRoutes";
import salaryItemRoutes from "./views/SalaryItem/SalaryItemRoutes";
import salaryIncrement from "./views/SalaryIncrement/SalaryIncrementRoutes";
import staffRoutes2 from "./views/HumanResourcesInformation_v2/StaffRoutes";
import familyRelationship from "./views/FamilyRelationship/FamilyRelationshipRoutes";
import projectRoutes from "./views/Project/ProjectRoutes";
import workingStatusRoutes from "./views/WorkingStatus/WorkingStatusRoutes";
import shifWorkRoutes from "./views/ShiftWork/ShiftWorkRoutes";
import timeSheetRoutes from "./views/TimeSheet/TimeSheetRoutes";
import generalRoutes from "./views/Home/GeneralRoutes";
import processRoutes from "./views/Process/ProcessRoutes";
import trainingRoute from "./views/TrainingCenter/TrainingRouters";
import trainingRouteInfor from "./views/TrainingCenterInfor/TrainingCenterRouters";
import servicesRouter from "./views/Servicess/ServicesRouter";
import fleetRoutes from "./views/FleetAndClientList/FleetAndClientRoutes";
import NewsEventRouter from "./views/NewsEvent/NewsEventRouter";
import newsEventInfoRoutes from "./views/NewsEventInfo/NewsEventInfoRoutes";

import ContactRoutes from "./views/Contact/ContactRoutes";
import Member from "./views/Member/MemberRoutes";

import aboutUsRoutes from "./views/AboutUs/AboutUsRoutes";

import RecruitmentRoutes from "./views/Recuitment/RecruitmentRoutes";
import RecuitmentInfoRoutes from "./views/RecuitmentInfo/RecruitmentInfoRoutes";
import contactUsRoute from "./views/ContactUs/ContactUsRoute";
const redirectRoute = [
  {
    path: ConstantList.ROOT_PATH,
    exact: true,
    component: () => <Redirect to={ConstantList.HOME_PAGE} />, //Luôn trỏ về HomePage được khai báo trong appConfig
  },
];

const errorRoute = [
  {
    component: () => <Redirect to={ConstantList.ROOT_PATH + "east-star-shipping/session/404"} />,
  },
];

const routes = [
  ...processRoutes,
  ...servicesRouter,
  ...fleetRoutes,
  ...generalRoutes,
  ...ContactRoutes,
  ...trainingRouteInfor,
  ...RecuitmentInfoRoutes,
  ...contactUsRoute,
  ...Member,
  ...sessionRoutes,
  ...dashboardRoutes,
  ...trainingRoute,
  ...RecruitmentRoutes,
  ...redirectRoute,
  ...userRoutes,
  ...roleRoutes,
  ...colorsRoutes,
  ...academicRoutes,
  ...ethnicsRoutes,
  ...religionRoutes,
  ...countryRoutes,
  ...staffRoutes,
  ...positionTitleRoutes,
  ...certificateRoutes,
  ...DepartmentRoutes,
  ...civilServantTypeRoutes,
  ...rewardRoutes,
  ...contractTypeRoutes,
  ...employeeStatusRoutes,
  ...locationRoutes,
  ...professionRoutes,
  ...civilServantCategoryRoutes,
  ...disciplineRoutes,
  ...educationDegreeRoutes,
  ...educationTypeRoutes,
  ...gradeRoutes,
  ...specialityRoutes,
  ...professionalDegreeRoutes,
  ...informaticDegreeRoutes,
  ...politicaltheoryLevelRoutes,
  ...salaryItemRoutes,
  ...salaryIncrement,
  ...staffRoutes2,
  ...familyRelationship,
  ...projectRoutes,
  ...workingStatusRoutes,
  ...aboutUsRoutes,
  ...newsEventInfoRoutes,
  ...NewsEventRouter,
  ...shifWorkRoutes,
  ...timeSheetRoutes,
  ...errorRoute,
];

export default routes;
