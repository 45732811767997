import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { withTranslation } from "react-i18next";

const Recuitment = EgretLoadable({
    loader: () => import("./Index")
});
const ViewComponentFleet = withTranslation()(Recuitment);

const fleetRoutes = [{
    path: ConstantList.ROOT_PATH + "east-star-shipping/recruitment",
    component: ViewComponentFleet
},];

export default fleetRoutes;
