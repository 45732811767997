import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";

const SalaryIncrement = EgretLoadable({
  loader: () => import("./SalaryIncrementIndex"),
});
const ViewComponent = SalaryIncrement;

const Routes = [
  {
    path: ConstantList.ROOT_PATH + "east-star-shipping/salary/salary-increment",
    exact: true,
    component: ViewComponent,
  },
];

export default Routes;