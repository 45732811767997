import React, { Component } from "react";
import {
    Dialog,
    Button,
    Grid,
    DialogActions,
    FormControl,
    Paper,
    DialogTitle,
    Icon,
    DialogContent,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Draggable from "react-draggable";
import { saveItem, addItem, updateItem } from "./ContactUsService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConstantList from "../../appConfig";
import axios from "axios";
import { style } from "dom-helpers";
import { green } from "@material-ui/core/colors";

toast.configure({
    autoClose: 2000,
    draggable: false,
    limit: 3,
});

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

class ContactEmail extends Component {
    state = {
        id: "",
        fullName: "",
        email: "",
        phone: "",
        address: "",
        required: "",
        shouldOpenNotificationPopup: false,
        Notification: "",
    };

    handleDialogClose = () => {
        this.setState({ shouldOpenNotificationPopup: false });
    };

    handleFormReset = () => {
        this.setState({
            id: "",
            fullName: "",
            email: "",
            phone: "",
            address: "",
            required: "",
            Notification: "",
        });
    };

    handleChange = (event, source) => {
        event.persist();
         ;
        if (source === "switch") {
            this.setState({ isActive: event.target.checked });
            return;
        }
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleFormSubmit = () => {
        let { id } = this.state;
        var { t } = this.props;
        if (id) {
            updateItem({
                ...this.state,
            }).then(() => {
                toast.success(t("Update thành công"));
                this.props.handleOKEditClose();
            });
        } else {
            saveItem({
                ...this.state,
            }).then(() => {
                toast.success(t("Thêm thành công"));
                this.props.handleOKEditClose();
            });
        }
    };

    componentWillMount() {
        let { open, handleClose, item } = this.props;
        this.setState({ ...item });
    }

    handleClose() {
        this.props.handleClose();
    }

    contact_us = {
        contact_to_us: "contact_us.contact_to_us",
        contact_email_us: "contact_us.contact_email_us",
        contact1: "contact_us.contact1",
        contact2: "contact_us.contact2",
        contact3: "contact_us.contact3",
        Fullname: "contact_us.Fullname",
        Email: "contact_us.Email",
        Phone: "contact_us.Phone",
        Address: "contact_us.Address",
        Required: "contact_us.Required",
        Website: "contact_us.Website",
        Fax: "contact_us.Fax",
        Send: "contact_us.Send",
        Reset: "contact_us.Reset",
        About: "contact_us.About",
    };

    render() {
        let {
            id,
            fullName,
            phone,
            email,
            address,
            required,
            shouldOpenNotificationPopup,
        } = this.state;
        let { open, handleClose, handleOKEditClose, t, i18n } = this.props;
        return (
            <Dialog
                open={open}
                PaperComponent={PaperComponent}
                onClose={() => this.props.handleClose()}
                maxWidth="md"
                fullWidth
                className="dialog-fixed-color"
            >
                <DialogTitle
                    style={{ cursor: "move", paddingBottom: "0px" }}
                    onClose={() => this.props.handleClose()}
                >
                    <h2 style={{ color: "#00016D", float: "left" }}>
                        {t(this.contact_us.contact_to_us)}
                    </h2>
                    {this.props.handleClose && (
                        <Button
                            variant="contained"
                            style={{
                                border: "1px solid #00016D",
                                backgroundColor: "#00016D",
                                color: "white",
                                margin: "0px",
                                float: "right",
                            }}
                            onClick={() => this.props.handleClose()}
                        >
                            X
                        </Button>
                    )}
                </DialogTitle>
                <ValidatorForm onSubmit={() => this.handleFormSubmit()} ref="form">
                    <DialogContent item md={12} lg={12} sm={12} xs={12}>
                        <p className="text1">{t(this.contact_us.contact1)}</p>
                        <p className="text1">{t(this.contact_us.contact2)}</p>
                    </DialogContent>
                    <DialogContent
                        style={{ display: "flex", justifyContent: "space-between" }}
                    >
                        <Grid container spacing={2}>
                            <Grid item md={5} lg={5} sm={5} xs={12}>
                                <TextValidator
                                    inputProps={{
                                        autocomplete: "new-password",
                                    }}
                                    className="w-100 mb-10"
                                    label={
                                        <span>
                                            <span style={{ color: "red" }}>*</span>
                                            {t(this.contact_us.Fullname)}
                                        </span>
                                    }
                                    onChange={this.handleChange}
                                    type="text"
                                    name="fullName"
                                    value={fullName}
                                    validators={["required"]}
                                    errorMessages={[t("general.required")]}
                                />
                                <TextValidator
                                    inputProps={{
                                        autocomplete: "new-password",
                                    }}
                                    className="w-100 mb-10"
                                    label={
                                        <span>
                                            <span style={{ color: "red" }}>*</span>
                                            {t(this.contact_us.Email)}
                                        </span>
                                    }
                                    onChange={this.handleChange}
                                    type="text"
                                    name="email"
                                    value={email}
                                    validators={["required"]}
                                    errorMessages={[t("general.required")]}
                                />
                                <TextValidator
                                    inputProps={{
                                        autocomplete: "new-password",
                                    }}
                                    className="w-100 mb-10"
                                    label={
                                        <span>
                                            <span style={{ color: "red" }}>*</span>
                                            {t(this.contact_us.Phone)}
                                        </span>
                                    }
                                    onChange={this.handleChange}
                                    type="text"
                                    name="phone"
                                    value={phone}
                                    validators={["required"]}
                                    errorMessages={[t("general.required")]}
                                />
                                <TextValidator
                                    inputProps={{
                                        autocomplete: "new-password",
                                    }}
                                    className="w-100 mb-10"
                                    label={
                                        <span>
                                            <span style={{ color: "red" }}>*</span>
                                            {t(this.contact_us.Address)}
                                        </span>
                                    }
                                    onChange={this.handleChange}
                                    type="text"
                                    name="address"
                                    value={address}
                                    validators={["required"]}
                                    errorMessages={[t("general.required")]}
                                />
                                <TextValidator
                                    inputProps={{
                                        autocomplete: "new-password",
                                    }}
                                    className="w-100 mb-10"
                                    label={
                                        <span>
                                            <span style={{ color: "red" }}>*</span>
                                            {t(this.contact_us.Required)}
                                        </span>
                                    }
                                    onChange={this.handleChange}
                                    type="text"
                                    name="required"
                                    value={required}
                                    validators={["required"]}
                                    errorMessages={[t("general.required")]}
                                    multiline
                                    maxRows={4}
                                    rows={4}
                                />
                            </Grid>

                            <Grid item md={7} lg={7} sm={7} xs={12}>
                                <p
                                    style={{ margin: "0px", fontWeight: "500", color: "#00016D" }}
                                >
                                    {t(this.contact_us.About)}
                                </p>

                                <p className="icon">
                                    <Icon fontSize="small" color="#202020">
                                        location_on
                                    </Icon>
                                    <b>{t(this.contact_us.Address)}: &nbsp; </b>
                                    {t(this.contact_us.contact3)}
                                </p>
                                <p className="icon">
                                    <Icon fontSize="small" color="#202020">
                                        local_phone
                                    </Icon>
                                    <b>{t(this.contact_us.Phone)}: &nbsp; </b>+84-225 3734698
                                </p>
                                <p className="icon">
                                    <Icon fontSize="small" color="#202020">
                                        faxSharp
                                    </Icon>
                                    <b>{t(this.contact_us.Fax)}: &nbsp; </b>+84-225 3734697
                                </p>
                                <p className="icon">
                                    <Icon fontSize="small" color="#202020">
                                        mailOutlineSharp
                                    </Icon>
                                    <b>{t(this.contact_us.Email)}: &nbsp; </b>
                                    crew@eastar-shipping.com
                                </p>
                                <p className="icon">
                                    <Icon fontSize="small" color="#202020">
                                        open_in_browser
                                    </Icon>
                                    <b>{t(this.contact_us.Website)}: &nbsp; </b>
                                    http://eastar-shipping.com/
                                </p>
                                <div>
                                    <a href="https://zalo.me/+6598403296" target="_blank">
                                        <img
                                            style={{ margin: "5px" }}
                                            width={40}
                                            src="../../assets/images/logos/zalo.png"
                                            alt="google.com"
                                        />
                                    </a>
                                    <a href="https://m.me/113056334634222" target="_blank">
                                        <img
                                            style={{ margin: "5px" }}
                                            width={40}
                                            src="../../assets/images/logos/messenger.png"
                                            alt=""
                                        />
                                    </a>
                                    <a
                                        href="https://api.whatsapp.com/send?phone=+6598403296&text=YourMessage"
                                        target="_blank"
                                    >
                                        <img
                                            style={{ margin: "5px" }}
                                            width={40}
                                            src="../../assets/images/logos/whatsapp.png"
                                            alt=""
                                        />
                                    </a>
                                    <a href="https://t.me/letan15798" target="_blank">
                                        <img
                                            style={{ margin: "5px" }}
                                            width={40}
                                            src="../../assets/images/logos/telegram.png"
                                            alt=""
                                        />
                                    </a>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions
                        style={{ justifyContent: "flex-start", marginLeft: "15px" }}
                    >
                        <div className="flex flex-space-between flex-middle mt-12 ml-5 mb-10">
                            <Button
                                variant="contained"
                                style={{
                                    marginRight: "15px",
                                    backgroundColor: "#3E3F95",
                                    color: "white",
                                }}
                                color="#00016D"
                                type="submit"
                            >
                                {t(this.contact_us.Send)}
                            </Button>
                            <Button
                                variant="contained"
                                className="mr-12"
                                boxSizing="border-box"
                                style={{
                                    marginRight: "15px",
                                    backgroundColor: "white",
                                }}
                                onClick={() => this.handleFormReset()}
                            >
                                {t(this.contact_us.Reset)}
                            </Button>
                        </div>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        );
    }
}

export default ContactEmail;
