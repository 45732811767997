import { makeAutoObservable } from "mobx";
import {
  pagingStaff,
  getStaff,
  createStaff,
  editStaff,
  deleteStaff,
  uploadImage,
  getGeneralInformation,
  createGeneralInformation,
  editGeneralInformation,
} from "./StaffService";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
toast.configure({
  autoClose: 2000,
  draggable: false,
  limit: 3,
});

export default class staffStore2 {
  staffList = [];
  selectedStaff = null;
  initialGeneralInformation = {
    id: "",
    firstName: "",
    lastName: "",
    displayName: "",
    gender: "",
    birthDate: null,
    birthPlace: "",
    permanentResidence: "",
    accommodationToday: "",
    idNumber: "",
    idNumberIssueDate: null,
    idNumberIssueBy: "",
    wards: "",
    nationality: null,
    ethnics: null,
    religion: null,
    email: "",
    phoneNumber: "",
    maritalStatus: "",
  };
  selectedGeneralInformation = null;
  selectedStaffList = [];
  totalElements = 0;
  totalPages = 0;
  page = 1;
  rowsPerPage = 5;
  keyword = "";
  loadingInitial = false;
  shouldOpenEditorDialog = false;
  shouldOpenConfirmationDialog = false;
  shouldOpenConfirmationDeleteListDialog = false;
  shouldOpenImportExcelDialog = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoadingInitial = (state) => {
    this.loadingInitial = state;
  };

  updatePageData = (item) => {
    if (item != null) {
      this.page = 1;
      this.keyword = item.keyword;
      this.search();
    } else {
      this.search();
    }
  };

  search = async () => {
    this.loadingInitial = true;
    var searchObject = {
      keyword: this.keyword,
      pageIndex: this.page,
      pageSize: this.rowsPerPage,
    };
    try {
      let data = await pagingStaff(searchObject);
      this.staffList = data
        ? data.data
          ? data.data.content
            ? data.data.content
            : []
          : []
        : [];
      this.totalElements = data.data.totalElements;
      this.totalPages = data.data.totalPages;

      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      toast.warning("toast.load_fail");
      this.setLoadingInitial(false);
    }
  };

  setPage = (page) => {
    this.page = page;
    this.updatePageData();
  };

  setRowsPerPage = (event) => {
    this.rowsPerPage = event.target.value;
    this.page = 1;
    this.updatePageData();
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  handleEditStaff = (id) => {
    this.getStaff(id);
  };

  handleClose = () => {
    this.shouldOpenEditorDialog = false;
    this.shouldOpenConfirmationDialog = false;
    this.shouldOpenConfirmationDeleteListDialog = false;
    this.shouldOpenImportExcelDialog = false;
    this.updatePageData();
  };

  handleDelete = (id) => {
    this.getStaff(id).then(() => {
      this.shouldOpenConfirmationDialog = true;
    });
  };

  handleDeleteList = () => {
    this.shouldOpenConfirmationDeleteListDialog = true;
  };

  handleConfirmDelete = () => {
    this.deleteStaff(this.selectedStaff.id);
  };

  handleselectedStaff = (staff) => {
    this.selectedStaff = staff;
  };

  getStaff = async (id) => {
    this.loadingInitial = true;
    if (id != null) {
      try {
        let data = await getStaff(id);
        this.handleSelectStaff(data.data);

        this.setLoadingInitial(false);
        return this.selectedStaff;
      } catch (error) {
        console.log(error);
        toast.warning("toast.get_fail");

        this.setLoadingInitial(false);
      }
    } else {
      this.handleSelectStaff(null);

      this.setLoadingInitial(false);
    }
  };

  getGeneralInformation = async (id) => {
    this.loadingInitial = true;
    if (id != null) {
      try {
        let data = await getGeneralInformation(id);
        this.handleSelectGeneralInformation(data.data);

        this.setLoadingInitial(false);
        return this.selectedGeneralInformation;
      } catch (error) {
        console.log(error);
        toast.warning("toast.get_fail");

        this.setLoadingInitial(false);
      }
    } else {
      this.handleSelectGeneralInformation(this.initialGeneralInformation);
      this.setLoadingInitial(false);
    }
  };

  handleSelectGeneralInformation = (staff) => {
    console.log(staff);
    this.selectedGeneralInformation = staff;
  };

  handleSelectStaff = (staff) => {
    this.selectedStaff = staff;
  };

  handleSelectListStaff = (staffs) => {
    this.selectedStaffList = staffs;
  };

  handleConfirmDeleteList = async () => {
    let listAlert = [];
    for (var i = 0; i < this.selectedStaffList.length; i++) {
      try {
        await deleteStaff(this.selectedStaffList[i].id);
      } catch (error) {
        listAlert.push(this.selectedStaffList[i].name);
        console.log(error);
        console.log(listAlert.toString());
        toast.warning("toast.delete_fail");
      }
    }
    this.handleClose();
    toast.success("toast.delete_success");
  };

  createStaff = async (staff) => {
    console.log(staff);

    try {
      if (staff && staff.file != null) {
        const formData = new FormData();
        formData.append("uploadfile", staff.file);
        let response = await uploadImage(formData);
        console.log(response);
        staff.imagePath = response.data.name;
      }

      await createStaff(staff);
      toast.success("toast.add_success");
      this.handleClose();
    } catch (error) {
      console.log(error);
      toast.warning("toast.add_fail");
    }
  };

  editStaff = async (staff) => {
    try {
      if (staff && staff.file != null) {
        const formData = new FormData();
        formData.append("uploadfile", staff.file);
        let response = await uploadImage(formData);
        console.log(response);
        staff.imagePath = response.data.name;
      }
      await editStaff(staff);
      toast.success("toast.update_success");
      this.handleClose();
    } catch (error) {
      console.log(error);
      toast.warning("toast.update_fail");
    }
  };

  deleteStaff = async (id) => {
    try {
      await deleteStaff(id);
      toast.success("toast.delete_success");
      this.handleClose();
    } catch (error) {
      console.log(error);
      toast.warning("toast.delete_fail");
    }
  };
  uploadImage = async (file) => {
    try {
      await uploadImage(file);
      toast.success("toast.delete_success");
    } catch (error) {
      console.log(error);
      toast.warning("toast.delete_fail");
    }
  };
  importExcel = () => {
    this.shouldOpenImportExcelDialog = true;
  };

  createGeneralInformation = async (staff) => {
    console.log(staff);

    try {
      if (staff && staff.file != null) {
        const formData = new FormData();
        formData.append("uploadfile", staff.file);
        let response = await uploadImage(formData);
        console.log(response);
        staff.imagePath = response.data.name;
      }

      await createGeneralInformation(staff);
      toast.success("toast.add_success");
      this.handleClose();
    } catch (error) {
      console.log(error);
      toast.warning("toast.add_fail");
    }
  };

  editGeneralInformation = async (staff) => {
    try {
      if (staff && staff.file != null) {
        const formData = new FormData();
        formData.append("uploadfile", staff.file);
        let response = await uploadImage(formData);
        console.log(response);
        staff.imagePath = response.data.name;
      }
      await editGeneralInformation(staff);
      toast.success("toast.update_success");
      this.handleClose();
    } catch (error) {
      console.log(error);
      toast.warning("toast.update_fail");
    }
  };
}
