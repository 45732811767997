import axios from "axios";
import ConstantList from "../../appConfig";
const API_PATH = ConstantList.API_ENPOINT + "/api/staff";
const EXCEL_PATH =
  ConstantList.API_ENPOINT + "/api/fileDownload/exportExcel_staff";

export const pagingStaff = (searchObject) => {
  var url = API_PATH + "/searchByPage";
  return axios.post(url, searchObject);
};

export const getStaff = (id) => {
  let url = API_PATH + "/" + id;
  return axios.get(url);
};

export const createStaff = (obj) => {
  let url = API_PATH;
  return axios.post(url, obj);
};

export const editStaff = (obj) => {
  console.log("fdfd");
  console.log(obj);
  let url = API_PATH + "/" + obj.id;
  return axios.put(url, obj);
};

export const deleteStaff = (id) => {
  let url = API_PATH + "/" + id;
  return axios.delete(url);
};

export const uploadImage = (formData) => {
  var url = ConstantList.API_ENPOINT + "/api/hr/file/image";
  return axios.post(url, formData);
};

export const exportToExcel = (searchObject) => {
  return axios({
    method: 'post',
    url: EXCEL_PATH,
    data: searchObject,
    responseType: 'blob',
  })
};

export const checkIdNumber = (obj) => {
  let url = API_PATH + "/checkIdNumber";
  return axios.post(url, obj);
};