import { EgretLoadable } from "egret";
import { authRoles } from "../../auth/authRoles";
import ConstantList from "../../appConfig";
import { withTranslation } from "react-i18next";

const Process = EgretLoadable({
    loader: () => import("./ProcessPage")
});
const ViewComponentProcess = withTranslation()(Process);

const processRoutes = [{
    path: ConstantList.ROOT_PATH + "east-star-shipping/process",
    component: ViewComponentProcess,
    auth: authRoles.admin
},];

export default processRoutes;
