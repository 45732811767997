import { makeAutoObservable } from "mobx";

import { pagingUsers, getUser, saveUser, deleteUser } from "./UserService";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
toast.configure({
  autoClose: 2000,
  draggable: false,
  limit: 3,
});

export default class UserStore {
  userList = [];
  roles = [];
  selectedUser = null;
  totalElements = 0;
  totalPages = 0;
  page = 1;
  rowsPerPage = 10;
  keyword = "";
  loadingInitial = false;
  shouldOpenEditorDialog = false;
  shouldOpenConfirmationDialog = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoadingInitial = (state) => {
    this.loadingInitial = state;
  };

  updatePageData = (item) => {
    if (item != null) {
      this.page = 1;
      this.keyword = item.keyword;
      this.search();
    } else {
      this.search();
    }
  };

  search = async () => {
    this.loadingInitial = true;
    var searchObject = {};
    searchObject.text = this.keyword;
    searchObject.pageIndex = this.page;
    searchObject.pageSize = this.rowsPerPage;

    try {
      let data = await pagingUsers(
        searchObject.pageIndex,
        searchObject.pageSize
      );
      this.userList = data.data.content;
      this.totalElements = data.data.totalElements;
      this.totalPages = data.data.totalPages;

      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  setPage = (page) => {
    this.page = page;
    this.updatePageData();
  };

  setRowsPerPage = (event) => {
    this.rowsPerPage = event.target.value;
    this.page = 1;
    this.updatePageData();
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  handleEditUser = (id) => {
    this.getUser(id).then(() => {
      this.shouldOpenEditorDialog = true;
    });
  };

  handleClose = () => {
    this.shouldOpenEditorDialog = false;
    this.shouldOpenConfirmationDialog = false;
    this.updatePageData();
  };

  handleDelete = (id) => {
    this.getUser(id).then(() => {
      this.shouldOpenConfirmationDialog = true;
    });
  };

  handleConfirmDelete = () => {
    this.deleteUser(this.selectedUser.id);
  };

  getUser = async (id) => {
    if (id != null) {
      try {
        let data = await getUser(id);
        this.selectedUser = data.data;
      } catch (error) {
        console.log(error);
        toast.warning("toast.get_fail");
      }
    } else {
      this.selectedUser = null;
    }
  };

  saveUser = async (user) => {
    try {
      await saveUser(user);
      toast.success("toast.update_success");
      this.handleClose();
    } catch (error) {
      console.log(error);
      toast.warning("toast.update_fail");
    }
  };

  deleteUser = async (id) => {
    try {
      await deleteUser(id);
      toast.success("toast.delete_success");
      this.handleClose();
    } catch (error) {
      console.log(error);
      toast.warning("toast.delete_fail");
    }
  };
}
