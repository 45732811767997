import { createContext, useContext } from "react";
import ColorsStore from "./views/Colors/ColorStore";
import AcademicStore from "./views/Academic/AcademicStore";
import RoleStore from "./views/Role/RoleStore";
import EthnicsStore from "./views/Ethnics/EthnicsStore";
import ReligionStore from "./views/Religion/ReligionStore";
import CountryStore from "./views/Country/CountryStore";
import StaffStore from "./views/HumanResourcesInformation/StaffStore";
import UserStore from "./views/User/UserStore";
import PositionTitleStore from "./views/PositionTitle/PositionTitleStore";
import SalaryItemStore from "./views/SalaryItem/SalaryItemStore";
import SalaryIncrementStore from "./views/SalaryIncrement/SalaryIncrementStore";
import CertificateStore from "./views/Certificate/CertificateStore";
import DepartmentStore from "./views/Department/DepartmentStore";
import CivilServantTypeStore from "./views/CivilServantType/CivilServantTypeStore";
import RewardStore from "./views/Reward/RewardStore";
import ContractTypeStore from "./views/ContractType/ContractTypeStore";
import EmployeeStatus from "./views/EmployeeStatus/EmployeeStatusStore";
import LocationStore from "./views/Location/LocationStore";
import CivilServantCategoryStore from "./views/CivilServantCategory/CivilServantCategoryStore";
import ProfessionStore from "./views/Profession/ProfessionStore";
import DisciplineStore from "./views/Discipline/DisciplineStore";
import GradeStore from "./views/Grade/GradeStore";
import EducationDegreeStore from "./views/EducationDegree/EducationDegreeStore";
import EducationTypeStore from "./views/EducationType/EducationTypeStore";
import SpecialityStore from "./views/Speciality/SpecialityStore";
import ProfessionalDegreeStore from "./views/ProfessionalDegree/ProfessionalDegreeStore";
import InformaticDegreeStore from "./views/InformaticDegree/InformaticDegreeStore";
import PoliticaltheoryLevel from "./views/PoliticaltheoryLevel/PoliticaltheoryLevelStore";
import StaffStore2 from "./views/HumanResourcesInformation_v2/StaffStore";
import FamilyRelationship from "./views/FamilyRelationship/FamilyRelationshipStore";
import GeneralInformationStore from "./views/HumanResourcesInformation_v2/HumanResourcesInformationStore/GeneralInformationStore";
import ProfileInformationStore from "./views/HumanResourcesInformation_v2/HumanResourcesInformationStore/ProfileInformationStore";
import ProjectStore from "./views/Project/ProjectStore";
import WorkingStatusStore from "./views/WorkingStatus/WorkingStatusStore";
import ShiftWorkStore from "./views/ShiftWork/ShiftWorkStore";
import TimeSheetStore from "./views/TimeSheet/TimeSheetStore";
export const store = {
  colorsStore: new ColorsStore(),
  academicStore: new AcademicStore(),
  roleStore: new RoleStore(),
  ethnicsStore: new EthnicsStore(),
  religionStore: new ReligionStore(),
  countryStore: new CountryStore(),
  staffStore: new StaffStore(),
  userStore: new UserStore(),
  positionTitleStore: new PositionTitleStore(),
  salaryItemStore: new SalaryItemStore(),
  salaryIncrementStore: new SalaryIncrementStore(),
  certificateStore: new CertificateStore(),
  departmentStore: new DepartmentStore(),
  civilServantTypeStore: new CivilServantTypeStore(),
  rewardStore: new RewardStore(),
  contractTypeStore: new ContractTypeStore(),
  employeeStatusStore: new EmployeeStatus(),
  locationStore: new LocationStore(),
  civilServantCategoryStore: new CivilServantCategoryStore(),
  professionStore: new ProfessionStore(),
  disciplineStore: new DisciplineStore(),
  gradeStore: new GradeStore(),
  educationDegreeStore: new EducationDegreeStore(),
  educationTypeStore: new EducationTypeStore(),
  specialityStore: new SpecialityStore(),
  professionalDegreeStore: new ProfessionalDegreeStore(),
  informaticDegreeStore: new InformaticDegreeStore(),
  politicaltheoryLevelStore: new PoliticaltheoryLevel(),
  staffStore2: new StaffStore2(),
  familyRelationshipStore: new FamilyRelationship(),
  generalInformationStore: new GeneralInformationStore(),
  profileInformationStore: new ProfileInformationStore(),
  projectStore: new ProjectStore(),
  workingStatusStore: new WorkingStatusStore(),
  shiftWorkStore: new ShiftWorkStore(),
  timeSheetStore: new TimeSheetStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
