import { EgretLoadable } from "egret";
import { authRoles } from "../../auth/authRoles";
import ConstantList from "../../appConfig";
import { withTranslation } from "react-i18next";

const FleetClient = EgretLoadable({
    loader: () => import("./Index")
});
const ViewComponentFleet = withTranslation()(FleetClient);

const fleetRoutes = [{
    path: ConstantList.ROOT_PATH + "east-star-shipping/fleet-client",
    component: ViewComponentFleet,
    auth: authRoles.admin
},];

export default fleetRoutes;
