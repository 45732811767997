import { makeAutoObservable } from "mobx";
import {
    getSalaryIncrement,
    saveSalaryIncrement,
    deleteSalaryIncrement,
    pagingSalaryIncrement,
    checkCode,
} from "./SalaryIncrementService";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

toast.configure({
    autoClose: 2000,
    draggable: false,
    limit: 3,
});

export default class SalaryIncrementStore {
    salaryIncrementList = [];
    selectedSalaryIncrement = null;
    selectedSalaryIncrementList = [];
    totalElements = 0;
    totalPages = 0;
    page = 1;
    rowsPerPage = 10;
    keyword = "";
    loadingInitial = false;
    shouldOpenEditorDialog = false;
    shouldOpenConfirmationDialog = false;
    shouldOpenConfirmationDeleteListDialog = false;

    constructor() {
        makeAutoObservable(this);
    }

    setLoadingInitial = (state) => {
        this.loadingInitial = state;
    };

    updatePageData = (item) => {
        if (item != null) {
            this.page = 1;
            this.keyword = item.keyword;
            this.search();
        } else {
            this.search();
        }
    };

    search = async () => {
        this.loadingInitial = true;
        var searchObject = {
            keyword: this.keyword,
            pageIndex: this.page,
            pageSize: this.rowsPerPage,
        };

        try {
            let data = await pagingSalaryIncrement(searchObject);
            this.salaryIncrementList = data.data.content;
            this.totalElements = data.data.totalElements;
            this.totalPages = data.data.totalPages;
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            toast.warning("toast.load_fail");
            this.setLoadingInitial(false);
        }
    };

    handleEditSalaryIncrement = (id) => {
        this.getSalaryIncrement(id).then(() => {
            this.shouldOpenEditorDialog = true;
        });
    }

    getSalaryIncrement = async (id) => {
        if (id != null) {
            try {
                let data = await getSalaryIncrement(id);
                this.handleSelectSalaryIncrement(data.data);
            } catch (error) {
                console.log(error);
                toast.warning("toast.get_fail");
            }
        } else {
            this.handleSelectSalaryIncrement(null);
        }
    };

    saveSalaryIncrement = async (salaryIncrement) => {
        try {
            let responseCheckCode = await checkCode(salaryIncrement.id, salaryIncrement.code);
            if (responseCheckCode.data) {
                toast.warning("toast.duplicate_code");
                this.handleClose();
            } else {
                await saveSalaryIncrement(salaryIncrement);
                toast.success("toast.add_success");
                this.handleClose();
            }
        } catch (error) {
            console.log(error);
            toast.warning("toast.add_fail");
        }
    };

    handleSelectSalaryIncrement = (salaryIncrement) => {
        this.selectedSalaryIncrement = salaryIncrement;
    };

    handleDeleteList = () => {
        this.shouldOpenConfirmationDeleteListDialog = true;
    };

    handleClose = () => {
        this.shouldOpenEditorDialog = false;
        this.shouldOpenConfirmationDialog = false;
        this.shouldOpenConfirmationDeleteListDialog = false;
        this.updatePageData();
    };

    handleConfirmDelete = async () => {
        try {
            await deleteSalaryIncrement(this.selectedSalaryIncrement.id);
            toast.success("toast.delete_success");
            this.handleClose();
        } catch (error) {
            console.log(error);
            toast.warning("toast.delete_fail");
        }
    };

    handleConfirmDeleteList = async () => {
        let listAlert = [];
        for (var i = 0; i < this.selectedSalaryIncrementList.length; i++) {
            try {
                await deleteSalaryIncrement(this.selectedSalaryIncrementList[i].id);
            } catch (error) {
                listAlert.push(this.selectedSalaryIncrementList[i].name);
                console.log(error);
                console.log(listAlert.toString());
                toast.warning("toast.delete_fail");
            }
        }
        this.handleClose();
        toast.success("toast.delete_success");
    };

    handleSelectListSalaryIncrement = (position) => {
        this.selectedSalaryIncrementList = position;
        console.log(this.selectedSalaryIncrementList);
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    setRowsPerPage = (event) => {
        this.rowsPerPage = event.target.value;
        this.page = 1;
        this.updatePageData();
    };

    handleEditSalaryIncrement = (id) => {
        this.getSalaryIncrement(id).then(() => {
            this.shouldOpenEditorDialog = true;
        });
    };

    handleDelete = (id) => {
        this.getSalaryIncrement(id).then(() => {
            this.shouldOpenConfirmationDialog = true;
        });
    };
}