import { EgretLoadable } from "egret";
import { authRoles } from "../../auth/authRoles";
import ConstantList from "../../appConfig";
import { withTranslation } from 'react-i18next';

const AboutUs = EgretLoadable({
    loader: () =>
        import("./AboutUsPage")
});
const ViewComponentAboutUs = withTranslation()(AboutUs);

const aboutUsRoutes = [{
    path: ConstantList.ROOT_PATH + "east-star-shipping/dashboard/homePage",
    component: ViewComponentAboutUs,
    auth: authRoles.admin,
    exact: true
},];

export default aboutUsRoutes;