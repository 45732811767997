import axios from "axios";
import ConstantList from "../../appConfig";
const API_PATH = ConstantList.API_ENPOINT + "/api/staff";
const EXCEL_PATH = ConstantList.API_ENPOINT + "/api/fileDownload/exportExcel_staff";
const API_PATH_V3 = ConstantList.API_ENPOINT + "/api/v3/staff";

export const pagingStaff = (searchObject) => {
  var url = API_PATH + "/searchByPage";
  return axios.post(url, searchObject);
};

export const getStaff = (id) => {
  let url = API_PATH + "/" + id;
  return axios.get(url);
};

export const createStaff = (obj) => {
  let url = API_PATH;
  return axios.post(url, obj);
};

export const editStaff = (obj) => {
  let url = API_PATH + "/" + obj.id;
  return axios.put(url, obj);
};

export const deleteStaff = (id) => {
  let url = API_PATH + "/" + id;
  return axios.delete(url);
};

export const uploadImage = (formData) => {
  var url = ConstantList.API_ENPOINT + "/api/hr/file/image";
  return axios.post(url, formData);
};

export const exportToExcel = (searchObject) => {
  return axios({
    method: 'post',
    url: EXCEL_PATH,
    data: searchObject,
    responseType: 'blob',
  })
};

export const getGeneralInformation = (id) => {
  let url = API_PATH_V3 + "/generalinformation/" + id;
  return axios.get(url);
};

export const createGeneralInformation = (obj) => {
  let url = API_PATH_V3+"/generalinformation";
  return axios.post(url, obj);
};

export const editGeneralInformation = (obj) => {
  let url = API_PATH_V3 + "/generalinformation/" + obj.id;
  return axios.put(url, obj);
};

export const getProfileInformation = (id) => {
  let url = API_PATH_V3 + "/profileInformation/" + id;
  return axios.get(url);
};

export const createProfileInformation = (obj) => {
  let url = API_PATH_V3+"/profileInformation";
  return axios.post(url, obj);
};

export const editProfileInformation = (obj) => {
  let url = API_PATH_V3 + "/profileInformation/" + obj.id;
  return axios.put(url, obj);
};