import {
    SET_OPEN_APPLY_FORM
} from "../actions/ApplyFormActions";

const initialState = {
    openApplyForm: false
};

const OpenApplyForm = (state = initialState, action) => {
    switch (action.type) {
        case SET_OPEN_APPLY_FORM:
            console.log(action.data)
            return {
                ...state,
                openApplyForm: action.data
            };
        default:
            return { ...state };
    }
};

export default OpenApplyForm;
