import React, { Component } from "react";
import {
  Dialog,
  Button,
  Grid,
  DialogActions,
  FormControl,
  Paper,
  DialogTitle,
  Icon,
  DialogContent,
} from "@material-ui/core";

import ContactDialog from "../../../app/views/Contact/ContactDialog";

class EgretHorizontalNav extends Component {
  state = {
    shouldOpenDialog: false,
    opened: false,
    activeLink: "",
  };

  handleClickSmooth = (id) => {
    return document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };

  renderLevels = (levels) => {
    const { t } = this.props;
    return levels.map((item, key) => {
      if (item.isVisible) {
        return (
          <a
            className="nav-item"
            onClick={() => this.handleClickSmooth(item.href)}
          >
            {t(item.name)}
          </a>
        );
      }
    });
  };

  handleClick = (key) => {
    console.log("KEY: ", key);
    key.preventDefault();
    this.setState({ activeLink: key, opened: true });
  };

  handleDialogClose = () => {
    this.setState({ shouldOpenDialog: false });
  };

  handleOKEditClose = () => {
    this.setState({ shouldOpenDialog: false });
  };

  render() {
    let max = this.props.max;
    let navigation = this.props.navigation;

    if (max && navigation.length > max) {
      // tạo nút more
      let childItem = {
        name: "More",
        icon: "more_vert",
        children: navigation.slice(max, navigation.length),
      };
      navigation = navigation.slice(0, max);
      navigation.push(childItem);
    }
    const { t, i18n } = this.props;
    return (
      <>
        <div className="horizontal-nav">
          <ul ref={this.wrapperRef} className="menu">
            {this.renderLevels(navigation)}
            <span
              className="nav-item"
              onClick={() => {
                this.setState({ shouldOpenDialog: true });
              }}
            >
              {t("Dashboard.contactUs")}
            </span>
          </ul>

          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <a
              style={{ display: "flex", alignItems: "center" }}
              href="https://zalo.me/+6598403296"
              target="_blank"
            >
              <img
                style={{ margin: "5px" }}
                width={30}
                src="../../assets/images/logos/zalo.png"
                alt="google.com"
              />
            </a>
            <a
              style={{ display: "flex", alignItems: "center" }}
              href="https://m.me/113056334634222"
              target="_blank"
            >
              <img
                style={{ margin: "5px" }}
                width={30}
                src="../../assets/images/logos/messenger.png"
                alt=""
              />
            </a>
            <a
              style={{ display: "flex", alignItems: "center" }}
              href="https://api.whatsapp.com/send?phone=+6598403296&text=YourMessage"
              target="_blank"
            >
              <img
                style={{ margin: "5px" }}
                width={30}
                src="../../assets/images/logos/whatsapp.png"
                alt=""
              />
            </a>
            <a
              style={{ display: "flex", alignItems: "center" }}
              href="https://t.me/letan15798"
              target="_blank"
            >
              <img
                style={{ margin: "5px" }}
                width={30}
                src="../../assets/images/logos/telegram.png"
                alt=""
              />
            </a>
          </div>
        </div>
        {this.state.shouldOpenDialog && (
          <ContactDialog
            t={t}
            i18n={i18n}
            handleClose={this.handleDialogClose}
            open={this.state.shouldOpenDialog}
            handleOKEditClose={this.handleOKEditClose}
          />
        )}
      </>
    );
  }
}
export default EgretHorizontalNav;
