import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { withTranslation } from "react-i18next";

const TrainingCenter = EgretLoadable({
    loader: () => import("./Index")
});
const ViewComponentFleet = withTranslation()(TrainingCenter);

const fleetRoutes = [{
    path: ConstantList.ROOT_PATH + "east-star-shipping/training-center/:id",
    component: ViewComponentFleet
},];

export default fleetRoutes;
