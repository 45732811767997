import { makeAutoObservable } from "mobx";
import {
  pagingTimeSheet,
  getTimeSheet,
  createTimeSheet,
  editTimeSheet,
  deleteTimeSheet,
  updateStatus,
} from "./TimeSheetService";
import { pagingProject } from "../Project/ProjectService";
import { pagingWorkingStatus } from "../WorkingStatus/WorkingStatusService";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

toast.configure({
  autoClose: 2000,
  draggable: false,
  limit: 3,
});

export default class TimeSheetStore {
  timeSheetList = [];
  listProject = [];
  listWorkingStatus = [];
  selectedTimeSheet = null;
  selectedTimeSheetList = [];
  selectedProject = null;
  totalElements = 0;
  totalPages = 0;
  page = 1;
  rowsPerPage = 10;
  keyword = "";
  loadingInitial = false;
  shouldOpenEditorDialog = false;
  shouldOpenConfirmationDialog = false;
  shouldOpenConfirmationDeleteListDialog = false;
  projectId = null;

  constructor() {
    makeAutoObservable(this);
  }

  setLoadingInitial = (state) => {
    this.loadingInitial = state;
  };
  handleChangeProject = (id) => {
    this.projectId = id;
  };
  handleSelectProject = (item) => {
    this.selectedProject = item;
    this.projectId = item.id;
    this.search();
  };

  updateStatus = async (id, workingStatusId) => {
    try {
      let data = await updateStatus(id, workingStatusId);
      // this.listProject = data.data.content;
      console.log(data);
      this.search();
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      toast.warning("toast.load_fail");
      this.setLoadingInitial(false);
    }
  };

  getProject = async () => {
    this.loadingInitial = true;
    var searchObject = {
      keyword: "",
      pageIndex: 1,
      pageSize: 10000,
    };
    try {
      let data = await pagingProject(searchObject);
      this.listProject = data.data.content;
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      toast.warning("toast.load_fail");
      this.setLoadingInitial(false);
    }
  };

  getWorkingStatus = async () => {
    this.loadingInitial = true;
    var searchObject = {
      keyword: "",
      pageIndex: 1,
      pageSize: 10000,
    };
    try {
      let data = await pagingWorkingStatus(searchObject);
      this.listWorkingStatus = data.data.content;
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      toast.warning("toast.load_fail");
      this.setLoadingInitial(false);
    }
  };

  updatePageData = (item) => {
    this.getProject();
    this.getWorkingStatus();
    if (item != null) {
      this.page = 1;
      if (item.keyword) {
        this.keyword = item.keyword;
      }
      if (item.projectId) {
        this.projectId = item.projectId;
      }

      this.search();
    } else {
      this.search();
    }
  };

  search = async () => {
    this.loadingInitial = true;
    var searchObject = {
      keyword: this.keyword,
      pageIndex: this.page,
      pageSize: this.rowsPerPage,
      projectId: this.projectId,
    };

    try {
      let data = await pagingTimeSheet(
        searchObject,
        searchObject.pageIndex,
        searchObject.pageSize
      );
      this.timeSheetList = data.data.content;
      this.totalElements = data.data.totalElements;
      this.totalPages = data.data.totalPages;
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      toast.warning("toast.load_fail");
      this.setLoadingInitial(false);
    }
  };

  setPage = (page) => {
    this.page = page;
    this.updatePageData();
  };

  setRowsPerPage = (event) => {
    this.rowsPerPage = event.target.value;
    this.page = 1;
    this.updatePageData();
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  handleEditTimeSheet = (id) => {
    this.getTimeSheet(id).then(() => {
      this.shouldOpenEditorDialog = true;
    });
  };

  handleClose = () => {
    this.shouldOpenEditorDialog = false;
    this.shouldOpenConfirmationDialog = false;
    this.shouldOpenConfirmationDeleteListDialog = false;
    this.updatePageData();
  };

  handleDelete = (id) => {
    this.getTimeSheet(id).then(() => {
      this.shouldOpenConfirmationDialog = true;
    });
  };

  handleDeleteList = () => {
    this.shouldOpenConfirmationDeleteListDialog = true;
  };

  handleConfirmDelete = async () => {
    try {
      await deleteTimeSheet(this.selectedTimeSheet.id);
      toast.success("toast.delete_success");
      this.handleClose();
    } catch (error) {
      console.log(error);
      toast.warning("toast.delete_fail");
    }
  };

  handleConfirmDeleteList = async () => {
    let listAlert = [];
    for (var i = 0; i < this.selectedTimeSheetList.length; i++) {
      try {
        await deleteTimeSheet(this.selectedTimeSheetList[i].id);
      } catch (error) {
        listAlert.push(this.selectedTimeSheetList[i].name);
        console.log(error);
        console.log(listAlert.toString());
        toast.warning("toast.delete_fail");
      }
    }
    this.handleClose();
    toast.success("toast.delete_success");
  };

  getTimeSheet = async (id) => {
    if (id != null) {
      try {
        let data = await getTimeSheet(id);
        console.log(data);
        this.handleSelectTimeSheet(data.data);
      } catch (error) {
        console.log(error);
        toast.warning("toast.get_fail");
      }
    } else {
      this.handleSelectTimeSheet(null);
    }
  };

  handleSelectTimeSheet = (timeSheet) => {
    this.selectedTimeSheet = timeSheet;
  };

  handleSelectListTimeSheet = (timeSheets) => {
    this.selectedTimeSheetList = timeSheets;
  };

  createTimeSheet = async (timeSheet) => {
    console.log(timeSheet);
    try {
      await createTimeSheet(timeSheet);
      toast.success("toast.add_success");
      this.handleClose();
    } catch (error) {
      console.log(error);
      toast.warning("toast.add_fail");
    }
  };

  editTimeSheet = async (timeSheet) => {
    try {
      await editTimeSheet(timeSheet);
      toast.success("toast.update_success");
      this.handleClose();
    } catch (error) {
      console.log(error);
      toast.warning("toast.update_fail");
    }
  };
}
