import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { withTranslation } from "react-i18next";

const ContactUs = EgretLoadable({
    loader: () => import("./Index")
});
const ViewComponentFleet = withTranslation()(ContactUs);

const contactUsRoute = [{
    path: ConstantList.ROOT_PATH + "east-star-shipping/contact-us",
    component: ViewComponentFleet
},];

export default contactUsRoute;
