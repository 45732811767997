import { makeAutoObservable } from "mobx";
import {
  pagingPosition,
  getPosition,
  createPosition,
  editPosition,
  deletePosition,
  checkCode,
} from "./PositionTitleService";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

toast.configure({
  autoClose: 2000,
  draggable: false,
  limit: 3,
});

export default class PositionTitleStore {
  positionTitleList = [];
  initialPositionTitle = {
    id: "",
    code: "",
    value: "",
  };
  selectedPositionTitle = this.initialPositionTitle;
  selectedPositionTitleList = [];
  totalElements = 0;
  totalPages = 0;
  page = 1;
  rowsPerPage = 10;
  keyword = "";
  loadingInitial = false;
  shouldOpenEditorDialog = false;
  shouldOpenConfirmationDialog = false;
  shouldOpenConfirmationDeleteListDialog = false;
  shouldOpenPositionTitlePopup = false;
  shouldOpenImportExcelDialog = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoadingInitial = (state) => {
    this.loadingInitial = state;
  };

  updatePageData = (item) => {
    if (item != null) {
      this.page = 1;
      this.keyword = item.keyword;
      this.search();
    } else {
      this.search();
    }
  };

  search = async () => {
    this.loadingInitial = true;
    var searchObject = {
      keyword: this.keyword,
      pageIndex: this.page,
      pageSize: this.rowsPerPage,
    };

    try {
      let data = await pagingPosition(searchObject);
      this.positionTitleList = data.data.content;
      this.totalElements = data.data.totalElements;
      this.totalPages = data.data.totalPages;

      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      toast.warning("toast.load_fail");
      this.setLoadingInitial(false);
    }
  };

  setPage = (page) => {
    this.page = page;
    this.updatePageData();
  };

  setRowsPerPage = (event) => {
    this.rowsPerPage = event.target.value;
    this.page = 1;
    this.updatePageData();
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  handleEditPosition = (id) => {
    this.getPosition(id).then(() => {
      this.shouldOpenEditorDialog = true;
    });
  };

  handleClose = () => {
    this.shouldOpenEditorDialog = false;
    this.shouldOpenConfirmationDialog = false;
    this.shouldOpenConfirmationDeleteListDialog = false;
    this.shouldOpenImportExcelDialog = false;
    this.updatePageData();
  };

  handleDelete = (id) => {
    this.getPosition(id).then(() => {
      this.shouldOpenConfirmationDialog = true;
    });
  };

  handleDeleteList = () => {
    this.shouldOpenConfirmationDeleteListDialog = true;
  };

  handleConfirmDelete = async () => {
    try {
      await deletePosition(this.selectedPositionTitle.id);
      toast.success("toast.delete_success");
      this.handleClose();
    } catch (error) {
      console.log(error);
      toast.warning("toast.delete_fail");
    }
  };

  handleConfirmDeleteList = async () => {
    let listAlert = [];
    for (var i = 0; i < this.selectedPositionTitleList.length; i++) {
      try {
        await deletePosition(this.selectedPositionTitleList[i].id);
      } catch (error) {
        listAlert.push(this.selectedPositionTitleList[i].name);
        console.log(error);
        console.log(listAlert.toString());
        toast.warning("toast.delete_fail");
      }
    }
    this.handleClose();
    toast.success("toast.delete_success");
  };

  getPosition = async (id) => {
    if (id != null) {
      try {
        let data = await getPosition(id);
        this.handleSelectPositionTitle(data.data);
      } catch (error) {
        console.log(error);
        toast.warning("toast.get_fail");
      }
    } else {
      this.handleSelectPositionTitle(this.initialPositionTitle);
    }
  };

  handleSelectPositionTitle = (position) => {
    this.selectedPositionTitle = position;
  };

  handleSelectListPositionTitle = (position) => {
    this.selectedPositionTitleList = position;
  };

  createPosition = async (position) => {
    try {
      let responseCheckCode = await checkCode(position.id, position.code);
      if (responseCheckCode.data) {
        toast.warning("toast.duplicate_code");
      } else {
        await createPosition(position);
        toast.success("toast.add_success");
        this.handleClose();
      }
    } catch (error) {
      console.log(error);
      toast.warning("toast.add_fail");
    }
  };

  editPosition = async (position) => {
    try {
      let responseCheckCode = await checkCode(position.id, position.code);
      if (responseCheckCode.data) {
        toast.warning("toast.duplicate_code");
      } else {
        await editPosition(position);
        toast.success("toast.update_success");
        this.handleClose();
      }
    } catch (error) {
      console.log(error);
      toast.warning("toast.update_fail");
    }
  };

  handleTogglePositionTitlePopup = () => {
    if (this.shouldOpenPositionTitlePopup === true) {
      this.shouldOpenPositionTitlePopup = false;
    } else {
      this.shouldOpenPositionTitlePopup = true;
    }
  };

  handleClosePopup = () => {
    this.shouldOpenPositionTitlePopup = false;
  };

  importExcel = () => {
    this.shouldOpenImportExcelDialog = true;
  };
}
