import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
const StaffIndex = EgretLoadable({
  loader: () => import("./StaffIndex"),
});
const StaffCreateEdit = EgretLoadable({
  loader: () => import("./StaffCreateEdit"),
});
const ViewComponent = StaffIndex;
const ViewStaffCreateEdit = StaffCreateEdit;

const Routes = [
  {
    path: ConstantList.ROOT_PATH + "east-star-shipping/staff",
    exact: true,
    component: ViewComponent,
  },
  {
    path: ConstantList.ROOT_PATH + "east-star-shipping/staff/edit/:id",
    exact: true,
    component: ViewStaffCreateEdit,
  },
  {
    path: ConstantList.ROOT_PATH + "east-star-shipping/staff/create",
    exact: true,
    component: ViewStaffCreateEdit,
  },
];

export default Routes;
