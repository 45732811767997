import { EgretLoadable } from "egret";
import { authRoles } from "../../auth/authRoles";
import ConstantList from "../../appConfig";
import { withTranslation } from "react-i18next";

const General = EgretLoadable({
    loader: () => import("./General")
});
const ViewComponentGeneral = withTranslation()(General);

const generalRoutes = [{
    path: ConstantList.ROOT_PATH + "east-star-shipping/homepage",
    component: ViewComponentGeneral,
    auth: authRoles.admin
},];

export default generalRoutes;
