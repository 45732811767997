import axios from "axios";
import ConstantList from "../../appConfig";
const API_PATH = ConstantList.API_ENPOINT + "/api/api/workingstatus";

export const pagingWorkingStatus = (searchObject) => {
  var url = API_PATH + "/search-by-page";
  return axios.post(url, searchObject);
};

export const getWorkingStatus = (id) => {
  let url = API_PATH + "/" + id;
  return axios.get(url);
};

export const createWorkingStatus = (obj) => {
  let url = API_PATH;
  return axios.post(url, obj);
};

export const editWorkingStatus = (obj) => {
  let url = API_PATH + "/" + obj.id;
  return axios.put(url, obj);
};

export const deleteWorkingStatus = (id) => {
  let url = API_PATH + "/" + id;
  return axios.delete(url);
};

export const checkCode = (id, code) => {
  const config = { params: { id: id, code: code } };
  var url = API_PATH + "/checkCode";
  return axios.get(url, config);
};

