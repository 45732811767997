import { makeAutoObservable } from "mobx";
import {
    getFamilyRelationship,
    createFamilyRelationship,
    editFamilyRelationship,
    deleteFamilyRelationship,
    pagingFamilyRelationship,
    checkCode,
} from "./FamilyRelationshipService";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

toast.configure({
    autoClose: 2000,
    draggable: false,
    limit: 3,
});

export default class FamilyRelationshipStore {
    familyRelationshipList = [];
    selectedFamilyRelationship = null;
    selectedFamilyRelationshipList = [];
    totalElements = 0;
    totalPages = 0;
    page = 1;
    rowsPerPage = 10;
    keyword = "";
    loadingInitial = false;
    shouldOpenEditorDialog = false;
    shouldOpenConfirmationDialog = false;
    shouldOpenConfirmationDeleteListDialog = false;

    constructor() {
        makeAutoObservable(this);
    }

    setLoadingInitial = (state) => {
        this.loadingInitial = state;
    };

    updatePageData = (item) => {
        if (item != null) {
            this.page = 1;
            this.keyword = item.keyword;
            this.search();
        } else {
            this.search();
        }
    };

    search = async () => {
        this.loadingInitial = true;
        var searchObject = {
            keyword: this.keyword,
            pageIndex: this.page,
            pageSize: this.rowsPerPage,
        };
        try {
            let data = await pagingFamilyRelationship(searchObject);
            this.familyRelationshipList = data.data.content;
            this.totalElements = data.data.totalElements;
            this.totalPages = data.data.totalPages;
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            toast.warning("toast.load_fail");
            this.setLoadingInitial(false);
        }
    };

    handleEditFamilyRelationship = (id) => {
        this.getFamilyRelationship(id).then(() => {
            this.shouldOpenEditorDialog = true;
        });
    };

    getFamilyRelationship = async (id) => {
        if (id != null) {
            try {
                let data = await getFamilyRelationship(id);
                this.handleSelectFamilyRelationship(data.data);
            } catch (error) {
                console.log(error);
                toast.warning("toast.get_fail");
            }
        } else {
            this.handleSelectFamilyRelationship(null);
        }
    };

    handleSelectFamilyRelationship = (familyRelationship) => {
        this.selectedFamilyRelationship = familyRelationship;
    };

    handleDeleteList = () => {
        this.shouldOpenConfirmationDeleteListDialog = true;
    };

    createFamilyRelationship = async (familyRelationship) => {
        try {
            let responseCheckCode = await checkCode(familyRelationship.id, familyRelationship.code);
            if (responseCheckCode.data) {
                toast.warning("toast.duplicate_code");
            } else {
                await createFamilyRelationship(familyRelationship);
                toast.success("toast.add_success");
                this.handleClose();
            }
        } catch (error) {
            console.log(error);
            toast.warning("toast.add_fail");
        }
    };

    editFamilyRelationship = async (familyRelationship) => {
        try {
            let responseCheckCode = await checkCode(familyRelationship.id, familyRelationship.code);
            if (responseCheckCode.data) {
                toast.warning("toast.duplicate_code");
            } else {
                await editFamilyRelationship(familyRelationship);
                toast.success("toast.update_success");
                this.handleClose();
            }

        } catch (error) {
            console.log(error);
            toast.warning("toast.update_fail");
        }
    };

    handleClose = () => {
        this.shouldOpenEditorDialog = false;
        this.shouldOpenConfirmationDialog = false;
        this.shouldOpenConfirmationDeleteListDialog = false;
        this.updatePageData();
    };

    handleConfirmDelete = async () => {
        try {
            console.log(this.selectedFamilyRelationship.id);
            let check = await deleteFamilyRelationship(this.selectedFamilyRelationship.id);
            console.log(check);
            toast.success("toast.delete_success");
            this.handleClose();
        } catch (error) {
            console.log(error);
            toast.warning("toast.delete_fail");
        }
    };

    handleDelete = (id) => {
        this.getFamilyRelationship(id).then(() => {
          this.shouldOpenConfirmationDialog = true;
        });
      };

    handleConfirmDeleteList = async () => {
        let listAlert = [];
        for (var i = 0; i < this.selectedFamilyRelationshipList.length; i++) {
            try {
                await deleteFamilyRelationship(this.selectedFamilyRelationshipList[i].id);
            } catch (error) {
                listAlert.push(this.selectedFamilyRelationshipList[i].name);
                console.log(error);
                console.log(listAlert.toString());
                toast.warning("toast.delete_fail");
            }
        }
        this.handleClose();
        toast.success("toast.delete_success");
    };

    handleSelectListFamilyRelationship = (familyRelationship) => {
        this.selectedFamilyRelationshipList = familyRelationship;
      };
}