import { makeAutoObservable } from "mobx";
import {
  pagingReligions,
  getReligion,
  createReligion,
  editReligion,
  deleteReligion,
  checkCode,
} from "./ReligionService";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

toast.configure({
  autoClose: 2000,
  draggable: false,
  limit: 3,
});

export default class ReligionStore {
  religionList = [];
  selectedReligion = null;
  selectedReligionList = [];
  totalElements = 0;
  totalPages = 0;
  page = 1;
  rowsPerPage = 10;
  keyword = "";
  loadingInitial = false;
  shouldOpenEditorDialog = false;
  shouldOpenConfirmationDialog = false;
  shouldOpenConfirmationDeleteListDialog = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoadingInitial = (state) => {
    this.loadingInitial = state;
  };

  updatePageData = (item) => {
    if (item != null) {
      this.page = 1;
      this.keyword = item.keyword;
      this.search();
    } else {
      this.search();
    }
  };

  search = async () => {
    this.loadingInitial = true;
    var searchObject = {
      keyword: this.keyword,
      pageIndex: this.page,
      pageSize: this.rowsPerPage,
    };

    try {
      let data = await pagingReligions(searchObject);
      this.religionList = data.data.content;
      this.totalElements = data.data.totalElements;
      this.totalPages = data.data.totalPages;
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      toast.warning("toast.load_fail");
      this.setLoadingInitial(false);
    }
  };

  setPage = (page) => {
    this.page = page;
    this.updatePageData();
  };

  setRowsPerPage = (event) => {
    this.rowsPerPage = event.target.value;
    this.page = 1;
    this.updatePageData();
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  handleEditReligion = (id) => {
    this.getReligion(id).then(() => {
      this.shouldOpenEditorDialog = true;
    });
  };

  handleClose = () => {
    this.shouldOpenEditorDialog = false;
    this.shouldOpenConfirmationDialog = false;
    this.shouldOpenConfirmationDeleteListDialog = false;
    this.updatePageData();
  };

  handleDelete = (id) => {
    this.getReligion(id).then(() => {
      this.shouldOpenConfirmationDialog = true;
    });
  };

  handleDeleteList = () => {
    this.shouldOpenConfirmationDeleteListDialog = true;
  };

  handleConfirmDelete = async () => {
    try {
      await deleteReligion(this.selectedReligion.id);
      toast.success("toast.delete_success");
      this.handleClose();
    } catch (error) {
      console.log(error);
      toast.warning("toast.delete_fail");
    }
  };

  handleConfirmDeleteList = async () => {
    let listAlert = [];
    for (var i = 0; i < this.selectedReligionList.length; i++) {
      try {
        await deleteReligion(this.selectedReligionList[i].id);
      } catch (error) {
        listAlert.push(this.selectedReligionList[i].name);
        console.log(error);
        console.log(listAlert.toString());
        toast.warning("toast.delete_fail");
      }
    }
    this.handleClose();
    toast.success("toast.delete_success");
  };

  getReligion = async (id) => {
    if (id != null) {
      try {
        let data = await getReligion(id);
        this.handleSelectReligion(data.data);
      } catch (error) {
        console.log(error);
        toast.warning("toast.get_fail");
      }
    } else {
      this.handleSelectReligion(null);
    }
  };

  handleSelectReligion = (religion) => {
    this.selectedReligion = religion;
  };

  handleSelectListReligion = (religions) => {
    this.selectedReligionList = religions;
    console.log(this.selectedReligionList);
  };

  createReligion = async (religion) => {
    try {
      let responseCheckCode = await checkCode(religion);
      if (responseCheckCode.data) {
        toast.warning("toast.duplicate_code");
      } else {
        await createReligion(religion);
        toast.success("toast.add_success");
        this.handleClose();
      }
    } catch (error) {
      console.log(error);
      toast.warning("toast.add_fail");
    }
  };

  editReligion = async (religion) => {
    try {
      let responseCheckCode = await checkCode(religion);
      if (responseCheckCode.data) {
        toast.warning("toast.duplicate_code");
      } else {
        await editReligion(religion);
        toast.success("toast.update_success");
        this.handleClose();
      }
    } catch (error) {
      console.log(error);
      toast.warning("toast.update_fail");
    }
  };
}
