import { makeAutoObservable } from "mobx";
import {
  pagingAcademics,
  getAcedemic,
  createAcedemic,
  editAcedemic,
  deleteAcedemic,
  checkCode,
} from "./AcademicService";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
toast.configure({
  autoClose: 2000,
  draggable: false,
  limit: 3,
  //etc you get the idea
});

export default class Store {
  acedemicList = [];
  selectedAcedemicList = [];
  selectedAcedemic = null;
  totalElements = 0;
  totalPages = 0;
  page = 1;
  rowsPerPage = 10;
  text = "";
  loadingInitial = false;
  shouldOpenEditorDialog = false;
  shouldOpenConfirmationDialog = false;
  shouldOpenConfirmationDeleteListDialog = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoadingInitial = (state) => {
    this.loadingInitial = state;
  };

  updatePageData = (acedemic) => {
    var searchObject = {};
    searchObject.text = this.text;
    searchObject.pageIndex = this.page;
    searchObject.pageSize = this.rowsPerPage;
    if (acedemic != null) {
      this.page = 1;
      this.text = acedemic.text;
      this.search(searchObject);
    } else {
      this.search(searchObject);
    }
  };

  search = async (searchObject) => {
    this.loadingInitial = true;
    try {
      let data = await pagingAcademics(searchObject);
      this.acedemicList = data.data.content;
      this.totalElements = data.data.totalElements;
      this.totalPages = data.data.totalPages;

      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  setPage = (page) => {
    this.page = page;
    this.updatePageData();
  };

  setRowsPerPage = (event) => {
    this.rowsPerPage = event.target.value;
    this.page = 1;
    this.updatePageData();
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  handleEditAcedemic = (id) => {
    this.getAcedemic(id).then(() => {
      this.shouldOpenEditorDialog = true;
    });
  };

  handleClose = () => {
    this.shouldOpenEditorDialog = false;
    this.shouldOpenConfirmationDialog = false;
    this.shouldOpenConfirmationDeleteListDialog = false;
    this.updatePageData();
  };

  handleDelete = (id) => {
    this.getAcedemic(id).then(() => {
      this.shouldOpenConfirmationDialog = true;
    });
  };

  handleConfirmDelete = () => {
    this.deleteAcedemic(this.selectedAcedemic.id);
  };

  handleDeleteList = () => {
    this.shouldOpenConfirmationDeleteListDialog = true;
  };

  handleConfirmDeleteList = async () => {
    let listAlert = [];
    for (var i = 0; i < this.selectedAcedemicList.length; i++) {
      try {
        await deleteAcedemic(this.selectedAcedemicList[i].id);
      } catch (error) {
        listAlert.push(this.selectedAcedemicList[i].name);
        console.log(error);
        console.log(listAlert.toString());
        toast.warning("toast.delete_fail");
      }
    }
    this.handleClose();
    toast.success("toast.delete_success");
  };

  getAcedemic = async (id) => {
    if (id != null) {
      try {
        let data = await getAcedemic(id);
        this.handleSelectAcademic(data.data);
      } catch (error) {
        console.log(error);
        toast.warning("toast.get_fail");
      }
    } else {
      this.handleSelectAcademic(null);
    }
  };

  handleSelectAcademic = (academic) => {
    this.selectedAcedemic = academic;
  };

  handleSelectListAcademic = (academics) => {
    this.selectedAcedemicList = academics;
  };

  createAcedemic = async (acedemic) => {
    try {
      let responseCheckCode = await checkCode(acedemic.id,acedemic.code);
      if (responseCheckCode.data) {
        toast.warning("toast.duplicate_code");
      } else {
        await createAcedemic(acedemic);
        toast.success("toast.add_success");
        this.handleClose();
      }
    } catch (error) {
      console.log(error);
      toast.warning("toast.add_fail");
    }
  };

  editAcedemic = async (acedemic) => {
    try {
      let responseCheckCode = await checkCode(acedemic.id,acedemic.code);
      if (responseCheckCode.data) {
        toast.warning("toast.duplicate_code");
      } else {
        await editAcedemic(acedemic);
        toast.success("toast.update_success");
        this.handleClose();
      }
    } catch (error) {
      console.log(error);
      toast.warning("toast.update_fail");
    }
  };

  deleteAcedemic = async (id) => {
    try {
      await deleteAcedemic(id);
      toast.success("toast.delete_success");
      this.handleClose();
    } catch (error) {
      console.log(error);
      toast.warning("toast.delete_fail");
    }
  };
}
