import { makeAutoObservable } from "mobx";
// import ConstantList from "../../appConfig";
import {
  pagingStaff,
  getStaff,
  createStaff,
  editStaff,
  deleteStaff,
  uploadImage,
  checkIdNumber,
} from "./StaffService";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
toast.configure({
  autoClose: 2000,
  draggable: false,
  limit: 3,
});

export default class StaffStore {
  staffList = [];
  selectedStaff = null;
  selectedStaffList = [];
  totalElements = 0;
  totalPages = 0;

  searcjObj = {
    pageIndex: 1,
    pageSize: 5,
    keyword: "",
    employeeStatusId: "",
    civilServantTypeId: "",
    academicTitleLevel: null,
    department: null,
    educationDegreeLevel: null,
  };

  loadingInitial = false;
  shouldOpenEditorDialog = false;
  shouldOpenConfirmationDialog = false;
  shouldOpenConfirmationDeleteListDialog = false;
  shouldOpenImportExcelDialog = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoadingInitial = (state) => {
    this.loadingInitial = state;
  };

  updatePageData = (item) => {
    if (item != null) {
      this.searcjObj = {
        ...item,
        pageIndex: 1,
        pageSize: 5,
      };
      this.search();
    } else {
      this.search();
    }
  };

  search = async () => {
    this.loadingInitial = true;

    var searchObject = {
      ...this.searcjObj,
    };
    try {
      let data = await pagingStaff(searchObject);
      this.staffList = data
        ? data.data
          ? data.data.content
            ? data.data.content
            : []
          : []
        : [];
      this.totalElements = data.data.totalElements;
      this.totalPages = data.data.totalPages;

      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      toast.warning("toast.load_fail");
      this.setLoadingInitial(false);
    }
  };

  setPageIndex = (pageIndex) => {
    this.searcjObj.pageIndex = pageIndex;
    this.updatePageData();
  };

  setPageSize = (event) => {
    this.searcjObj.pageSize = event.target.value;
    this.searcjObj.pageIndex = 1;
    this.updatePageData();
  };

  handleChangePage = (event, newPage) => {
    this.setPageIndex(newPage);
  };

  handleEditStaff = (id) => {
    this.getStaff(id);
  };

  handleClose = () => {
    this.shouldOpenEditorDialog = false;
    this.shouldOpenConfirmationDialog = false;
    this.shouldOpenConfirmationDeleteListDialog = false;
    this.shouldOpenImportExcelDialog = false;
    this.updatePageData();
  };

  handleDelete = (id) => {
    this.getStaff(id).then(() => {
      this.shouldOpenConfirmationDialog = true;
    });
  };

  handleDeleteList = () => {
    this.shouldOpenConfirmationDeleteListDialog = true;
  };

  handleConfirmDelete = () => {
    this.deleteStaff(this.selectedStaff.id);
  };

  handleselectedStaff = (staff) => {
    this.selectedStaff = staff;
  };

  getStaff = async (id) => {
    this.loadingInitial = true;
    if (id != null) {
      try {
        let data = await getStaff(id);
        this.handleSelectStaff(data.data);

        this.setLoadingInitial(false);
        return this.selectedStaff;
      } catch (error) {
        console.log(error);
        toast.warning("toast.get_fail");

        this.setLoadingInitial(false);
      }
    } else {
      this.handleSelectStaff(null);

      this.setLoadingInitial(false);
    }
  };

  handleSelectStaff = (staff) => {

    if (staff && staff.personCertificate && staff.personCertificate.length > 0) {
      staff.personCertificate = staff.personCertificate.map((e) => {
        return { ...e, issueDate: e.issueDate ? new Date(e.issueDate) : null }
      })
    }
    if (staff && staff.positions && staff.positions.length > 0) {
      staff.positions = staff.positions.map((e) => {
        return {
          ...e, fromDate: e.fromDate ? new Date(e.fromDate) : null,
          toDate: e.toDate ? new Date(e.toDate) : null
        }
      })
    }

    if (staff && staff.familyRelationships && staff.familyRelationships.length > 0) {
      staff.familyRelationships = staff.familyRelationships.map((e) => {
        return { ...e, birthDate: e.birthDate ? new Date(e.birthDate) : null }
      })
    }

    if (staff && staff.educationHistory && staff.educationHistory.length > 0) {
      staff.educationHistory = staff.educationHistory.map((e) => {
        return {
          ...e, endDate: e.endDate ? new Date(e.endDate) : null,
          startDate: e.startDate ? new Date(e.startDate) : null
        }
      })
    }

    if (staff && staff.agreements && staff.agreements.length > 0) {
      staff.agreements = staff.agreements.map((e) => {
        return {
          ...e, endDate: e.endDate ? new Date(e.endDate) : null,
          startDate: e.startDate ? new Date(e.startDate) : null,
          signedDate: e.signedDate ? new Date(e.signedDate) : null,
        }
      })
    }

    if (staff && staff.stafInsuranceHistory && staff.stafInsuranceHistory.length > 0) {
      staff.stafInsuranceHistory = staff.stafInsuranceHistory.map((e) => {
        return {
          ...e, endDate: e.endDate ? new Date(e.endDate) : null,
          startDate: e.startDate ? new Date(e.startDate) : null,
        }
      })
    }

    if (staff && staff.salaryHistory && staff.salaryHistory.length > 0) {
      staff.salaryHistory = staff.salaryHistory.map((e) => {
        return { ...e, decisionDate: e.decisionDate ? new Date(e.decisionDate) : null }
      })
    }



    this.selectedStaff = {
      ...staff, birthDate: staff?.birthDate ? new Date(staff?.birthDate) : null,
      idNumberIssueDate: staff?.idNumberIssueDate ? new Date(staff?.idNumberIssueDate) : null,
      contractDate: staff?.contractDate ? new Date(staff?.contractDate) : null,
      recruitmentDate: staff?.recruitmentDate ? new Date(staff?.recruitmentDate) : null,
      dateOfReceivingPosition: staff?.dateOfReceivingPosition ? new Date(staff?.dateOfReceivingPosition) : null,
      startDate: staff?.startDate ? new Date(staff?.startDate) : null,
      dateOfReceivingAllowance: staff?.dateOfReceivingAllowance ? new Date(staff?.dateOfReceivingAllowance) : null,
      salaryStartDate: staff?.salaryStartDate ? new Date(staff?.salaryStartDate) : null,

    };

  };

  handleSelectListStaff = (staffs) => {
    this.selectedStaffList = staffs;
  };

  handleConfirmDeleteList = async () => {
    let listAlert = [];
    for (var i = 0; i < this.selectedStaffList.length; i++) {
      try {
        await deleteStaff(this.selectedStaffList[i].id);
      } catch (error) {
        listAlert.push(this.selectedStaffList[i].name);
        console.log(error);
        console.log(listAlert.toString());
        toast.warning("toast.delete_fail");
      }
    }
    this.handleClose();
    toast.success("toast.delete_success");
  };

  createStaff = async (staff) => {
    try {
      staff.displayName = staff.lastName + (staff.lastName ? " " : "") + staff.firstName;
      // let reposeCheckIdNumber = await checkIdNumber(staff);
      // if(reposeCheckIdNumber.data){
      //   toast.warning("toast.idNumber_duplicate");
      //   return false;
      // }else{
      if (staff && staff.file != null) {
        const formData = new FormData();
        formData.append("uploadfile", staff.file);
        let response = await uploadImage(formData);
        console.log(response);
        staff.imagePath = response.data.name;
      }
      await createStaff(staff);
      toast.success("toast.add_success");
      this.handleClose();
      // }
    } catch (error) {
      console.log(error);
      toast.warning("toast.add_fail");
    }
  };

  editStaff = async (staff) => {
    try {
      staff.displayName = staff.lastName + (staff.lastName ? " " : "") + staff.firstName;
      if (staff && staff.file != null) {
        const formData = new FormData();
        formData.append("uploadfile", staff.file);
        let response = await uploadImage(formData);
        console.log(response);
        staff.imagePath = response.data.name;
      }
      await editStaff(staff);
      toast.success("toast.update_success");
      this.handleClose();
      //}
    } catch (error) {
      console.log(error);
      toast.warning("toast.update_fail");
    }
  };

  deleteStaff = async (id) => {
    try {
      await deleteStaff(id);
      toast.success("toast.delete_success");
      this.handleClose();
    } catch (error) {
      console.log(error);
      toast.warning("toast.delete_fail");
    }
  };
  uploadImage = async (file) => {
    try {
      await uploadImage(file);
      toast.success("toast.delete_success");
    } catch (error) {
      console.log(error);
      toast.warning("toast.delete_fail");
    }
  };
  importExcel = () => {
    this.shouldOpenImportExcelDialog = true;
  };


}
