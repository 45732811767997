import { makeAutoObservable } from "mobx";
import {
  pagingEmployeeStatus,
  getEmployeeStatus,
  createEmployeeStatus,
  editEmployeeStatus,
  deleteEmployeeStatus,
  checkCode,
} from "./EmployeeStatusService";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

toast.configure({
  autoClose: 2000,
  draggable: false,
  limit: 3,
});

export default class EmployeeStatusStore {
  employeeStatusList = [];
  selectedEmployeeStatus = null;
  selectedEmployeeStatusList = [];
  totalElements = 0;
  totalPages = 0;
  page = 1;
  rowsPerPage = 10;
  keyword = "";
  loadingInitial = false;
  shouldOpenEditorDialog = false;
  shouldOpenConfirmationDialog = false;
  shouldOpenConfirmationDeleteListDialog = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoadingInitial = (state) => {
    this.loadingInitial = state;
  };

  updatePageData = (item) => {
    if (item != null) {
      this.page = 1;
      this.keyword = item.keyword;
      this.search();
    } else {
      this.search();
    }
  };

  search = async () => {
    this.loadingInitial = true;
    var searchObject = {
      keyword: this.keyword,
      pageIndex: this.page,
      pageSize: this.rowsPerPage,
    };

    try {
      let data = await pagingEmployeeStatus(searchObject);
      this.employeeStatusList = data.data.content;
      this.totalElements = data.data.totalElements;
      this.totalPages = data.data.totalPages;
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      toast.warning("toast.load_fail");
      this.setLoadingInitial(false);
    }
  };

  setPage = (page) => {
    this.page = page;
    this.updatePageData();
  };

  setRowsPerPage = (event) => {
    this.rowsPerPage = event.target.value;
    this.page = 1;
    this.updatePageData();
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  handleEditEmployeeStatus = (id) => {
    this.getEmployeeStatus(id).then(() => {
      this.shouldOpenEditorDialog = true;
    });
  };

  handleClose = () => {
    this.shouldOpenEditorDialog = false;
    this.shouldOpenConfirmationDialog = false;
    this.shouldOpenConfirmationDeleteListDialog = false;
    this.updatePageData();
  };

  handleDelete = (id) => {
    this.getEmployeeStatus(id).then(() => {
      this.shouldOpenConfirmationDialog = true;
    });
  };

  handleDeleteList = () => {
    this.shouldOpenConfirmationDeleteListDialog = true;
  };

  handleConfirmDelete = async () => {
    try {
      await deleteEmployeeStatus(this.selectedEmployeeStatus.id);
      toast.success("toast.delete_success");
      this.handleClose();
    } catch (error) {
      console.log(error);
      toast.warning("toast.delete_fail");
    }
  };

  handleConfirmDeleteList = async () => {
    let listAlert = [];
    for (var i = 0; i < this.selectedEmployeeStatusList.length; i++) {
      try {
        await deleteEmployeeStatus(this.selectedEmployeeStatusList[i].id);
      } catch (error) {
        listAlert.push(this.selectedEmployeeStatusList[i].name);
        console.log(error);
        console.log(listAlert.toString());
        toast.warning("toast.delete_fail");
      }
    }
    this.handleClose();
    toast.success("toast.delete_success");
  };

  getEmployeeStatus = async (id) => {
    if (id != null) {
      try {
        let data = await getEmployeeStatus(id);
        this.handleSelectEmployeeStatus(data.data);
      } catch (error) {
        console.log(error);
        toast.warning("toast.get_fail");
      }
    } else {
      this.handleSelectEmployeeStatus(null);
    }
  };

  handleSelectEmployeeStatus = (employeeStatus) => {
    this.selectedEmployeeStatus = employeeStatus;
  };

  handleSelectListEmployeeStatus = (employeeStatuss) => {
    this.selectedEmployeeStatusList = employeeStatuss;
    console.log(this.selectedEmployeeStatusList);
  };

  createEmployeeStatus = async (employeeStatus) => {
    try {
      let response = await checkCode(employeeStatus.id, employeeStatus.code);
      if (response.data) {
        toast.warning("toast.duplicate_code");
      } else {
        await createEmployeeStatus(employeeStatus);
        toast.success("toast.add_success");
        this.handleClose();
      }
    } catch (error) {
      console.log(error);
      toast.warning("toast.add_fail");
    }
  };

  editEmployeeStatus = async (employeeStatus) => {
    try {
      let response = await checkCode(employeeStatus.id, employeeStatus.code);
      if (response.data) {
        toast.warning("toast.duplicate_code");
      } else {
        await editEmployeeStatus(employeeStatus);
        toast.success("toast.update_success");
        this.handleClose();
      }
    } catch (error) {
      console.log(error);
      toast.warning("toast.update_fail");
    }
  };
}
