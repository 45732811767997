import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const Contact = EgretLoadable({
  loader: () => import("./ContactDialog")
});
const ViewComponent = withTranslation()(Contact);
const ContactRoutes = [
  {
    path: ConstantList.ROOT_PATH + "east-star-shipping/contact",
    exact: true,
    component: ViewComponent
  }
];

export default ContactRoutes;
