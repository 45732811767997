import { makeAutoObservable } from "mobx";
import {
  getSalaryItem,
  saveSalaryItem,
  deleteSalaryItem,
  pagingSalaryItem,
  checkCode,
} from "./SalaryItemService";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

toast.configure({
  autoClose: 2000,
  draggable: false,
  limit: 3,
});

export default class SalaryItemStore {
  salaryItemList = [];
  selectedSalaryItem = null;
  selectedSalaryItemList = [];
  totalElements = 0;
  totalPages = 0;
  page = 1;
  rowsPerPage = 10;
  keyword = "";
  loadingInitial = false;
  shouldOpenEditorDialog = false;
  shouldOpenConfirmationDialog = false;
  shouldOpenConfirmationDeleteListDialog = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoadingInitial = (state) => {
    this.loadingInitial = state;
  };

  handleSelectListSalaryItem = (position) => {
    this.selectedSalaryItemList = position;
    console.log(this.selectedSalaryItemList);
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  setRowsPerPage = (event) => {
    this.rowsPerPage = event.target.value;
    this.page = 1;
    this.updatePageData();
  };

  handleEditSalaryItem = (id) => {
    this.getSalaryItem(id).then(() => {
      this.shouldOpenEditorDialog = true;
    });
  };

  getSalaryItem = async (id) => {
    if (id != null) {
      try {
        let data = await getSalaryItem(id);
        this.handleSelectSalaryItem(data.data);
      } catch (error) {
        console.log(error);
        toast.warning("toast.get_fail");
      }
    } else {
      this.handleSelectSalaryItem(null);
    }
  };

  handleSelectSalaryItem = (salaryItem) => {
    this.selectedSalaryItem = salaryItem;
  };

  saveSalaryItem = async (salaryItem) => {
    try {
      let responseCheckCode = await checkCode(salaryItem.id, salaryItem.code);
      if (responseCheckCode.data) {
        toast.warning("toast.duplicate_code");
        this.handleClose();
      } else {
        await saveSalaryItem(salaryItem);
        toast.success("toast.add_success");
        this.handleClose();
      }
    } catch (error) {
      console.log(error);
      toast.warning("toast.add_fail");
    }
  };

  handleDelete = (id) => {
    this.getSalaryItem(id).then(() => {
      this.shouldOpenConfirmationDialog = true;
    });
  };

  updatePageData = (item) => {
    if (item != null) {
      this.page = 1;
      this.keyword = item.keyword;
      this.search();
    } else {
      this.search();
    }
  };

  search = async () => {
    this.loadingInitial = true;
    var searchObject = {
      keyword: this.keyword,
      pageIndex: this.page,
      pageSize: this.rowsPerPage,
    };

    try {
      let data = await pagingSalaryItem(searchObject);
      this.salaryItemList = data.data.content;
      this.totalElements = data.data.totalElements;
      this.totalPages = data.data.totalPages;
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      toast.warning("toast.load_fail");
      this.setLoadingInitial(false);
    }
  };

  handleDeleteList = () => {
    this.shouldOpenConfirmationDeleteListDialog = true;
  };

  handleClose = () => {
    this.shouldOpenEditorDialog = false;
    this.shouldOpenConfirmationDialog = false;
    this.shouldOpenConfirmationDeleteListDialog = false;
    this.updatePageData();
  };
  handleConfirmDelete = async () => {
    try {
      console.log(this.selectedSalaryItem.id);
      let check = await deleteSalaryItem(this.selectedSalaryItem.id);
      console.log(check);
      toast.success("toast.delete_success");
      this.handleClose();
    } catch (error) {
      console.log(error);
      toast.warning("toast.delete_fail");
    }
  };
  handleConfirmDeleteList = async () => {
    let listAlert = [];
    for (var i = 0; i < this.selectedSalaryItemList.length; i++) {
      try {
        await deleteSalaryItem(this.selectedSalaryItemList[i].id);
      } catch (error) {
        listAlert.push(this.selectedSalaryItemList[i].name);
        console.log(error);
        console.log(listAlert.toString());
        toast.warning("toast.delete_fail");
      }
    }
    this.handleClose();
    toast.success("toast.delete_success");
  };
}
