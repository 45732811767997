import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { withTranslation } from "react-i18next";

const NewsEvent = EgretLoadable({
    loader: () => import("./Index")
});
const ViewComponentFleet = withTranslation()(NewsEvent);

const NewsEventRoutes = [{
    path: ConstantList.ROOT_PATH + "east-star-shipping/news-events",
    component: ViewComponentFleet
},];

export default NewsEventRoutes;
