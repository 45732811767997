import React, { Component } from "react";
import { Breadcrumb } from "egret";
import {
  Grid,
  TextField,
  Checkbox,
  Card,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import viLocale from "date-fns/locale/vi";
import { withStyles } from "@material-ui/styles";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Button } from "@material-ui/core";
import "./style.css";
class Form extends Component {
  render() {
    return (
      <ValidatorForm
        ref="form"
        onSubmit={this.handleSubmit}
        onError={(errors) => console.log(errors)}
        noValidate={true} // notice this
      >
        <Grid container justifyContent="center" className="mt-10">
          <Grid item md={6}>
            <Grid className="mb-10">
              <img
                src="https://lh3.googleusercontent.com/RILL_Xa-Kf4q0ia2RixPARcfKzJsOqij0WS_PSBzg78YLlAxJal6YY6h01LTbY4oU0i5nDjxjsvlXvgni5aO2J0J6cOSez_TI4XMdtL7k_OS83zCpXYybih6ssWSzDE00A=w1438"
                alt=""
                className="rounded"
                style={{ width: "100%" }}
              />
            </Grid>
            <Card className="card" spacing={1}>
              <Grid className="mb-10 box">
                <h1>ĐĂNG KÝ ỨNG TUYỂN</h1>
                Anh em đăng ký ứng tuyển theo mẫu bên dưới. Công ty TNHH VTB Sao
                Phương Đông sẽ liên lạc với anh em khi có job phù hợp.
              </Grid>
            </Card>
            <Grid className="mb-10">
              <Card className="card" spacing={1}>
                <p class="head">Họ Và Tên *</p>
                <TextValidator
                  label="Mời bạn nhập câu trả lời"
                  onChange={this.handleChange}
                  name="name"
                  required
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    "this field is required",
                    "email is not valid",
                  ]}
                />
              </Card>
            </Grid>
            <Grid className="mb-10">
              <Card className="card" spacing={1}>
                <p class="head">Ngày sinh *</p>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={viLocale}>
                  <KeyboardDatePicker
                    fullWidth
                    openTo="year"
                    views={["year", "month", "date"]}
                    autoOk
                    variant="inline"
                    invalidDateMessage={"Sai định dạng"}
                    inputVariant="outlined"
                    format={"dd/MM/yyyy"}
                    size="small"
                    maxDateMessage={"Sai định dạng"}
                    InputAdornmentProps={{ position: "end" }}
                  />
                </MuiPickersUtilsProvider>
              </Card>
            </Grid>
            <Grid className="mb-10">
              <Card className="card" spacing={1}>
                <p class="head">Địa chỉ email</p>
                <TextValidator
                  label="Mời bạn nhập câu trả lời"
                  onChange={this.handleChange}
                  name="name"
                  required
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    "this field is required",
                    "email is not valid",
                  ]}
                />
              </Card>
            </Grid>
            <Grid className="mb-10">
              <Card className="card" spacing={1}>
                <p class="head">Số điện thoại *</p>
                <TextValidator
                  label="Mời bạn nhập câu trả lời"
                  onChange={this.handleChange}
                  name="name"
                  required
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    "this field is required",
                    "email is not valid",
                  ]}
                />
              </Card>
            </Grid>
            <Grid className="mb-10">
              <Card className="card" spacing={1}>
                <p class="head">Địa chỉ nơi ở</p>
                <TextValidator
                  label="Mời bạn nhập câu trả lời"
                  onChange={this.handleChange}
                  name="name"
                  required
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    "this field is required",
                    "email is not valid",
                  ]}
                />
              </Card>
            </Grid>

            <Grid className="mb-10">
              <Card className="card" spacing={1}>
                <Grid class="checkbox">
                  <p class="head">Chức danh ứng tuyển</p>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox />} label="Đại học" />
                    <FormControlLabel control={<Checkbox />} label="Cao Đẳng" />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Trung Cấp"
                    />
                    <FormControlLabel control={<Checkbox />} label="Sơ Cấp" />
                    <Grid>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Mục khác:"
                      />

                      <TextField />
                    </Grid>
                  </FormGroup>
                </Grid>
              </Card>
            </Grid>
            <Grid className="mb-10">
              <Card className="card" spacing={1}>
                <Grid class="checkbox">
                  <p class="head">Chức danh ứng tuyển</p>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Thuyền trưởng"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Máy trưởng"
                    />
                    <FormControlLabel control={<Checkbox />} label="Đại phó" />
                    <FormControlLabel control={<Checkbox />} label="Máy hai" />
                    <FormControlLabel control={<Checkbox />} label="Phó 2" />
                    <FormControlLabel control={<Checkbox />} label="Máy 3" />
                    <FormControlLabel control={<Checkbox />} label="Phó 3" />
                    <FormControlLabel control={<Checkbox />} label="Máy 4" />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Sỹ quan điện / Thợ điện"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Thủy thủ trưởng"
                    />
                    <FormControlLabel control={<Checkbox />} label="Thợ cả" />
                    <FormControlLabel control={<Checkbox />} label="Thủy thủ" />
                    <FormControlLabel control={<Checkbox />} label="Thợ máy" />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Thực tập sỹ quan"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Bếp / phục vụ"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Thực tập thủy thủ / thợ máy"
                    />
                    <Grid>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Mục khác:"
                      />

                      <TextField />
                    </Grid>
                  </FormGroup>
                </Grid>
              </Card>
              <Grid className="mb-10">
                <Card className="card" spacing={1}>
                  <Grid class="checkbox">
                    <p class="head">Chức danh ứng tuyển</p>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox />} label="1 năm" />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="2 năm trở lên"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Chưa có kinh nghiệm"
                      />
                      <Grid>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Mục khác:"
                        />

                        <TextField />
                      </Grid>
                    </FormGroup>
                  </Grid>
                </Card>
              </Grid>
              <Grid className="mb-10">
                <Card className="card" spacing={1}>
                  <p class="head">Chức danh ứng tuyển</p>
                  <TextValidator
                    label="Mức lương mong muốn
                  "
                    onChange={this.handleChange}
                    name="name"
                    required
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      "this field is required",
                      "email is not valid",
                    ]}
                  />
                </Card>
              </Grid>
              <Grid className="mb-10">
                <Card className="card" spacing={1}>
                  <Grid class="checkbox">
                    <p class="head">Chức danh ứng tuyển</p>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Tàu hàng rời 20,000DWT trở lên"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Tàu hàng dưới 20,000DWT"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Tàu Container"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Tàu dầu / tàu hóa chất"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Tàu Gas"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Tàu ô tô"
                      />
                      <Grid>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Mục khác:"
                        />

                        <TextField />
                      </Grid>
                    </FormGroup>
                  </Grid>
                </Card>
              </Grid>
              <Grid className="mb-10">
                <Card className="card" spacing={1}>
                  <Grid class="checkbox">
                    <p class="head">Chức danh ứng tuyển</p>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Thuyền viên Trung Quốc"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Thuyền viên Philippines"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Thuyền viên  Ấn Độ"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Thuyền viên Đông Âu"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Option 5"
                      />

                      <Grid>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Mục khác:"
                        />

                        <TextField />
                      </Grid>
                    </FormGroup>
                  </Grid>
                </Card>
              </Grid>
              <Grid className="mb-10">
                <Card className="card" spacing={1}>
                  <Grid class="checkbox">
                    <p class="head">Chức danh ứng tuyển</p>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Càng sớm càng tốt"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="tháng 12/2021"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Tháng 1/2022"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Tháng 2/2022"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Tháng 3/2022"
                      />

                      <Grid>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Mục khác:"
                        />

                        <TextField />
                      </Grid>
                    </FormGroup>
                  </Grid>
                </Card>
                <Grid className="mb-10">
                  <Card className="card" spacing={1}>
                    <Grid class="checkbox">
                      <p class="head">Sổ thuyền viên (nên nén file PDF)</p>
                      <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={this.handleOpenComponent}
                      >
                        Thêm tệp
                      </Button>
                    </Grid>
                  </Card>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={this.handleOpenComponent}
                >
                  Gửi
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ValidatorForm>
    );
  }
}

export default Form;
