import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { withTranslation } from "react-i18next";

const NewsEventInfo = EgretLoadable({
    loader: () => import("./Index"),
  });
  const ViewComponent = withTranslation()(NewsEventInfo);
  
  const NewsEventInfoRoutes = [
    {
      path: ConstantList.ROOT_PATH + "east-star-shipping/news-events/details/:eventId",
      exact: true,
      component: ViewComponent,
    },
  ];

export default NewsEventInfoRoutes;
