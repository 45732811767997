import { makeAutoObservable } from "mobx";
import {
  pagingInformaticDegree,
  getInformaticDegree,
  createInformaticDegree,
  editInformaticDegree,
  deleteInformaticDegree,
  checkCode,
} from "./InformaticDegreeService";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

toast.configure({
  autoClose: 2000,
  draggable: false,
  limit: 3,
});

export default class InformaticDegreeStore {
  informaticDegreeList = [];
  selectedInformaticDegree = null;
  selectedInformaticDegreeList = [];
  totalElements = 0;
  totalPages = 0;
  page = 1;
  rowsPerPage = 10;
  keyword = "";
  loadingInitial = false;
  shouldOpenEditorDialog = false;
  shouldOpenConfirmationDialog = false;
  shouldOpenConfirmationDeleteListDialog = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoadingInitial = (state) => {
    this.loadingInitial = state;
  };

  updatePageData = (item) => {
    if (item != null) {
      this.page = 1;
      this.keyword = item.keyword;
      this.search();
    } else {
      this.search();
    }
  };

  search = async () => {
    this.loadingInitial = true;
    var searchObject = {
      keyword: this.keyword,
      pageIndex: this.page,
      pageSize: this.rowsPerPage,
    };

    try {
      let data = await pagingInformaticDegree(searchObject);
      this.informaticDegreeList = data.data.content;
      this.totalElements = data.data.totalElements;
      this.totalPages = data.data.totalPages;
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      toast.warning("toast.load_fail");
      this.setLoadingInitial(false);
    }
  };

  setPage = (page) => {
    this.page = page;
    this.updatePageData();
  };

  setRowsPerPage = (event) => {
    this.rowsPerPage = event.target.value;
    this.page = 1;
    this.updatePageData();
  };

  handleChangePage = (event, newPage) => {
    this.setPage(newPage);
  };

  handleEditInformaticDegree = (id) => {
    this.getInformaticDegree(id).then(() => {
      this.shouldOpenEditorDialog = true;
    });
  };

  handleClose = () => {
    this.shouldOpenEditorDialog = false;
    this.shouldOpenConfirmationDialog = false;
    this.shouldOpenConfirmationDeleteListDialog = false;
    this.updatePageData();
  };

  handleDelete = (id) => {
    this.getInformaticDegree(id).then(() => {
      this.shouldOpenConfirmationDialog = true;
    });
  };

  handleDeleteList = () => {
    this.shouldOpenConfirmationDeleteListDialog = true;
  };

  handleConfirmDelete = async () => {
    try {
      await deleteInformaticDegree(this.selectedInformaticDegree.id);
      toast.success("toast.delete_success");
      this.handleClose();
    } catch (error) {
      console.log(error);
      toast.warning("toast.delete_fail");
    }
  };

  handleConfirmDeleteList = async () => {
    let listAlert = [];
    for (var i = 0; i < this.selectedInformaticDegreeList.length; i++) {
      try {
        await deleteInformaticDegree(this.selectedInformaticDegreeList[i].id);
      } catch (error) {
        listAlert.push(this.selectedInformaticDegreeList[i].name);
        console.log(error);
        console.log(listAlert.toString());
        toast.warning("toast.delete_fail");
      }
    }
    this.handleClose();
    toast.success("toast.delete_success");
  };

  getInformaticDegree = async (id) => {
    if (id != null) {
      try {
        let data = await getInformaticDegree(id);
        this.handleSelectInformaticDegree(data.data);
      } catch (error) {
        console.log(error);
        toast.warning("toast.get_fail");
      }
    } else {
      this.handleSelectInformaticDegree(null);
    }
  };

  handleSelectInformaticDegree = (informaticDegree) => {
    this.selectedInformaticDegree = informaticDegree;
  };

  handleSelectListInformaticDegree = (informaticDegree) => {
    this.selectedInformaticDegreeList = informaticDegree;
    console.log(this.selectedInformaticDegreeList);
  };

  createInformaticDegree = async (informaticDegree) => {
    try {
      let response = await checkCode(informaticDegree.id, informaticDegree.code);
      if (response.data) {
        toast.warning("toast.duplicate_code");
      } else {
        await createInformaticDegree(informaticDegree);
        toast.success("toast.add_success");
        this.handleClose();
      }
    } catch (error) {
      console.log(error);
      toast.warning("toast.add_fail");
    }
  };

  editInformaticDegree = async (informaticDegree) => {
    try {
      let response = await checkCode(informaticDegree.id, informaticDegree.code);
      if (response.data) {
        toast.warning("toast.duplicate_code");
      } else {
        await editInformaticDegree(informaticDegree);
        toast.success("toast.update_success");
        this.handleClose();
      }
    } catch (error) {
      console.log(error);
      toast.warning("toast.update_fail");
    }
  };
}
