import { EgretLoadable } from "egret";
import { authRoles } from "../../auth/authRoles";
import ConstantList from "../../appConfig";
import { withTranslation } from 'react-i18next';
import Form from './Form'
const Member = EgretLoadable({
    loader: () =>
        import("./MemberPage")
});

const ViewComponent = withTranslation()(Member);
const ViewComponentForm = withTranslation()(Form);


const dashboardRoutes = [{
    path: ConstantList.ROOT_PATH + "dashboard/east-star-shipping/Member",
    component: ViewComponent,
    auth: authRoles.admin,
    exact: true
}, {
    path: ConstantList.ROOT_PATH + "dashboard/east-star-shipping/Member/Form",
    component: ViewComponentForm,
}];


export default dashboardRoutes;